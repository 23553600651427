const i18nService = {
  defaultLanguage: "en",

  languages: [
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "assets/media/flags/226-united-states.svg",
    },
    {
      lang: "ptbr",
      name: "Português do Brasil",
      flag: process.env.BASE_URL + "assets/media/flags/255-brazil.svg",
    },
    {
      lang: "id",
      name: "Bahasa Indonesia",
      flag: process.env.BASE_URL + "assets/media/flags/209-indonesia.svg",
    },
    {
      lang: "es",
      name: "Español",
      flag: process.env.BASE_URL + "assets/media/flags/128-spain.svg",
    },
    {
      lang: "de",
      name: "Deutsche",
      flag: process.env.BASE_URL + "assets/media/flags/162-germany.svg",
    },
    {
      lang: "fr",
      name: "Français",
      flag: process.env.BASE_URL + "assets/media/flags/195-france.svg",
    },
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    if (navigator.languages != undefined) {
      const langCode = navigator.languages[0].slice(0, 2);
      if (langCode == "es") {
        this.defaultLanguage = "es";
      } else if (langCode == "pt") {
        this.defaultLanguage = "ptbr";
      } else if (langCode == "id") {
        this.defaultLanguage = "id";
      } else if (langCode == "de") {
        this.defaultLanguage = "de";
      } else if (langCode == "fr") {
        this.defaultLanguage = "fr";
      }
    }
    return localStorage.getItem("language") || this.defaultLanguage;
  },
};

export default i18nService;
