import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import ApiService from "./common/api.service";
// import MockService from "./common/mock/mock.service";
// import { VERIFY_AUTH } from "./store/auth.module";

import JwtService from "@/common/jwt.service";
import { postRequest } from "@/api/request.js";
import i18nService from "@/common/i18n.service.js";
Vue.config.productionTip = false;
import "@/mixins/permissionMixin";

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";
import JsonCSV from "vue-json-csv";
Vue.component("downloadCsv", JsonCSV);

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select'
Vue.component("ModelListSelect", ModelListSelect);
// API service init
// ApiService.init();

// Remove this to disable mock API
// MockService.init();

// Ensure we checked auth before each page load.
let uri = window.location.href.split("?");
if (uri.length == 2) {
  let tmp = "";
  tmp = uri[1].split("=");
  if (tmp.length == 2 && tmp[0] == "token") {
    var token = tmp[1];
    postRequest({
      action: "getAdminInfo",
      token: token,
    }).then((resUser) => {
      if (resUser && resUser.returncode) {
        JwtService.saveToken(token);
        JwtService.saveLoginUser(resUser.returndata.adminname);
        JwtService.saveTfaState(resUser.returndata.twofa_enabled);
        JwtService.saveTfaAllowedState(resUser.returndata.twofa_allowed);
        JwtService.saveSecurityWarningState(
          resUser.returndata.security_warning
        );
        JwtService.saveSecurityWarningLevel(
          resUser.returndata.security_warning_level
        );
        JwtService.saveUserRole(resUser.returndata.adminaccess);
        JwtService.saveTimeout(resUser.returndata.ui_timeout);
        const langCode = resUser.returndata.language;
        if (langCode == "pt-br") {
          i18nService.setActiveLanguage("ptbr");
        } else {
          i18nService.setActiveLanguage(langCode);
        }
        postRequest({
          action: "getPermissions",
          token: token,
        }).then((adminPermission) => {
          if (adminPermission && adminPermission.returncode) {
            JwtService.savePermission(adminPermission.returndata);
          }
          router.push("home", () => router.go(0));
        });
      } else {
        router.replace("/login");
      }
    });
  }
}

router.beforeEach((to, from, next) => {
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  const createNewPassPage = ["/forgotPass"];
  if (createNewPassPage.includes(to.path)) {
    return next();
  } else {
    const publicPages = ["/login"];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.id_token;

    if (authRequired && !loggedIn) {
      return next("/login");
    }
    next();
  }
});

// Scroll page to top on every route change
setTimeout(() => {
  window.scrollTo(0, 0);
}, 100);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
