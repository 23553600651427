// import JwtService from "@/common/jwt.service";
import i18nService from "@/common/i18n.service.js";

// action types
export const SETLANGUAGE = "setLanguage";

// mutation types
export const SET_LANGUAGE = "setLanguage";
export const SET_ERROR = "setError";

const state = {
  language: i18nService.getActiveLanguage,
};

const getters = {
  currentLanguage(state) {
    return state.language;
  },
};

const actions = {
  [SETLANGUAGE](context, credentials) {
    return new Promise((resolve) => {
      context.commit(SET_LANGUAGE, credentials);
      resolve(credentials);
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_LANGUAGE](state, data) {
    if (data) {
      window.localStorage.setItem("language", data);
      state.language = data;
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
