import ApiService from "@/common/api.service";

// action types
export const TIMEZONES = "timezones";
export const LANGUAGES = "languages";
export const DATEFORMATS = "dataformats";

// mutation types
export const SET_TIMEZONES = "setTimezones";
export const SET_LANGUAGES = "setLanguages";
export const SET_DATEFORMATS = "setDataformats";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  timezones: {},
  languages: {},
  dateformats: {},
};

const getters = {
  getTimezones(state) {
    return state.timezones;
  },
};

const actions = {
  [TIMEZONES](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("", credentials)
        .then((data) => {
          context.commit(SET_TIMEZONES, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_ERROR, data);
        });
    });
  },
  [LANGUAGES](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("", credentials)
        .then((data) => {
          context.commit(SET_LANGUAGES, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_ERROR, data);
        });
    });
  },
  [DATEFORMATS](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("", credentials)
        .then((data) => {
          context.commit(SET_DATEFORMATS, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_ERROR, data);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_TIMEZONES](state, res) {
    if (res.returncode) {
      state.timezones = res.returndata;
      state.errors = {};
    } else {
      state.errors = ["Server Error!"];
    }
  },
  [SET_LANGUAGES](state, res) {
    if (res.returncode) {
      let updatedReturnData = [];
      for (const [key, value] of Object.entries(res.returndata)) {
        updatedReturnData.push({ value: key, name: value });
      }
      state.languages = updatedReturnData;
      state.errors = {};
    } else {
      state.errors = ["Server Error!"];
    }
  },
  [SET_DATEFORMATS](state, res) {
    if (res.returncode) {
      let updatedReturnData = [];
      let i;
      for (i in res.returndata) {
        updatedReturnData.push({ value: res.returndata[i] });
      }
      state.dateformats = updatedReturnData;
      state.errors = {};
    } else {
      state.errors = ["Server Error!"];
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
