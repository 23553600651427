// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language. ",
  },
  MENU: {
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    HOME: "Home",
    SECURITY: "Security",
    SECURITY_INCIDENTS: "Security Incidents",
    TWOFA: "2FA",
    USERS: "Users",
    MIGRATIONS: "Migrations",
    DOMAINS: "Domains",
    COMPLIANCE: "Compliance",
    ALIASES: "Aliases",
    PUBLIC_FOLDERS: "Public Folders",
    LISTS: "Lists",
    FILTERING: "Filtering",
    WHITELIST: "Whitelist",
    BLACKLIST: "Blacklist",
    AUTO_WHITELIST: "Auto Whitelist",
    ADMIN_IPACCESS_LISTS: "Admin IP Access Lists",
    IPACCESS_LISTS: "IP Access Lists",
    IPTRUSTED: "User Trusted Login IPs",
    BRANDING: "Branding",
    SETTINGS: "Settings",
    LOGS: "Logs",
    INCOMING_LOGS: "Incoming Logs",
    OUTGOING_LOGS: "Outgoing Logs",
    LAST_LOGINS: "Last Logins",
    ALIAS_DOMAINS: "Alias Domains",
    SMARTLISTS: "Smart Lists",
    SUBADMINS: "Sub-Admins",
    CHAT: "Zulip",
  },
  COMMON: {
    ACTION: "Action",
    ADMIN: "Admin",
    DATE: "Date",
    DAYS_ARRAY: {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      7: "Sunday",
    },
    RPERPAGE: "Row per page",
    ENABLE: "Enable",
    ENABLED: "Enabled",
    DISABLE: "Disable",
    DISABLED: "Disabled",
    SAVE: "Save",
    STEP: "Step",
    CANCEL: "Cancel",
    CLOSE: "Close",
    RESET: "Reset",
    NEXT: "Next",
    SEL_DOMAIN: "Select Domain",
    SEL_USER: "Select User",
    SEARCH: "Search",
    USERNAME: "Username",
    USER_NAME: "User name",
    DOMAIN: "Domain",
    TYPE: "Type",
    RANGE: "Range",
    PASSWORD: "Password",
    QUOTA: "Quota(GB)",
    NEW: "New",
    COPY: "Copy",
    COPY_CLIPBOARD: "Copy to Clipboard",
    ADD: "Add",
    REMOVE: "Remove",
    LANGUAGE: "Language",
    LOCALIZATION: "Localization",
    DATE_FORMAT: "Date Format",
    UPDATE: "Update ",
    DEL_WARNING: "All data will be lost! Are you sure you want to remove?",
    DEL_ITEM_WARNING:
      "All data will be lost! Are you sure you want to remove {item}?",
    DEL_CONFIRM: "Delete Confirmation",
    HOME_COUNTRY: "Home Country",
    TRAVEL_MODE: "Travel Mode",
    TIMEZONE: "Timezone",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    YES: "Yes",
    NO: "No",
    NO_DATA: "No data available",
    ADDRESS: "Address",
    CHECK_SPAM: "Check Spam",
    CHECK_VIRUS: "Check Virus",
    BROWSE: "Browse",
    DESCRIPTION: "Description",
    DELETE: "Delete",
    EDIT: "Edit ",
    NOT_ALLOWED: "Not Allowed",
    ALLOWED: "Allowed",
    ALLOW: "Allow",
    DAY: " day",
    DAYS: " days",
    DESTINATION: "Destination",
    VALUE: "Value",
    FOOTER: "Footer",
    MORE_DETAILS: "More details",
    OPTIONAL: "Optional",
    OTPASSWORD: "OTPassword",
    OTHER_ADMIN: "Other Admin",
    SEARCH_OPTIONS: "Search Options",
    SEARCH_RESULTS: "Search Results",
    SOURCE: "Source",
    STATUS: "Status",
    ADMIN_PANEL: "Admin Panel",
    SUCCESS: "Success",
    ERROR: "Error",
    SUCCESS_ALERT: "Operation was successful!",
    ERROR_ALERT: "Operation was filed!",
    WARNING: "Warning",
    OK: "Ok",
    HI: "Hi",
    TAB_LOGO: "Admin Control Panel",
    ALL_DOMAINS: "All Domains",
    FULL_NAME: "Full Name",
    WAIT_MESSAGE: "Please allow 5 minutes to propagate the changes",
    REGULAR: "Regular",
    REFRESH: "Refresh"
  },
  AUTO_CLOSE: {
    TITLE: "Automatic Logout",
    DESC: "We didn't detect any activity for almost 5 minutes. Stay logged in?",
    TIME_REMAIN: "{sec} seconds to logout.",
  },
  HOME: {
    ADMIN_ACCOUNT: "Admin Account",
    STATISTICS: "Statistics",
    REMOTE_IP: "Remote IP",
    BASIC_ACCOUNTS: "Basic Accounts",
    ENHANCED_ACCOUNTS: "Enhanced Accounts",
    DESC1:
      "For increased security, consider enabling 2 Factor Authentication for this account.",
    DESC2:
      "Simply install an OTP App such as <a href='https://www.authy.com/download'> &nbsp; Authy &nbsp; </a> and click on 2FA in the menu.",
    DESC3: "2 Factor Authentication not enabled!",
  },
  TWOFA: {
    AUTHY: "Authy",
    TFAUTH: "Two Factor Authentication",
    SEL_STATUS: "Select Status",
    DEC1: "Please make sure you already installed an OTP App, such as",
    DEC2: "In step 2, you will have to scan the QR Code using the OTP App.",
    DEC3: "When you are ready, hit the 'NEXT' button below.",
    SCAN_DEC1: "Scan this QR code using your OTP App.",
    SCAN_DEC2: "Once the account is added, you will be given a 6 digit code.",
    SCAN_DEC3: "Enter the code in the field below.",
    SCAN_DEC4: "Hit 'SAVE' button below before your code expires.",
    CHALLENGE: "Challenge",
    DISABLE_TITLE: "Disable Two Factor Authentication",
    DDEC1:
      "To disable 2 Factor Authentication, insert the code from your OTP App ( Authy ).",
    DDEC2:
      "Hit 'SAVE' button below before your code expires. If this happens, insert again your newly generated code from your OTP App ( Authy ).",
  },
  USERS: {
    QUSAGE: "Quota & Usage",
    EXPORT_RESULTS: "Export Results",
    NEW_USER: "New User",
    DEL_USER: "Delete User",
    ADD_USER: "Add User",
    UPDATE_USER: "Update User",
    PASS_DEC: "Please make sure to save this password.",
    ACCOUNT_TYPE: "Account Type",
    ACCOUNT_TYPE_OEX: "OEX Account Type",
    NAME_OF_USER: "Name of User",
    FACTOR_AUTH: "2Factor Auth",
    ONETIME_TITLE: "One time password created!",
    ONETIME_SUCCESS: "This password is valid for 5 minutes only!",
    BASIC: "Basic",
    ENHANCED: "Enhanced",
    AUDIT_ACCOUNT: "Audit Account",
    NEVER: "Never",
    LAST_LOGIN: "Last Login",
    BASIC_ACCOUNT: "Basic Account",
    ENHANCED_ACCOUNT: "Enhanced Account",
    DISABLE_SMTP_ONLY: "Disable SMTP only",
    USER_QUOTA: "{num1}GB free of {num2}GB",
    USER_CONTROL_PANEL: "User Control Panel",
    EXCHANGE: "Exchange Connector",
    EXCHANGE_ACCOUNTS: "Exchange Connectors",
    OEX: "OEX",
    OFFICE365: "External Connector",
    OFFICE365_ACCOUNTS: "External Connectors",
    MORE_OPTIONS: "More Options",
    LESS_OPTIONS: "Less Options",
    ONLY_LOCAL_SMTP: "Only Local SMTP",
    PERM: {
      ACCESS_HOURS: "Access email only between hours",
      APP_PASS: "Application passwords",
      CAN_ACCESS: "Can access:",
      CHANGE_PASS: "Change password",
      DELIV_RULES: "Delivery rules",
      DISABLED_INFO: "Users in red are either disabled or they have restricted login hours",
      EXTENSIONS: "Extensions",
      FOLDERS_CLEANUP: "Folders cleanup",
      FORWARDS: "Forwards",
      HOURS_BOTH: "Please provide both start hour and end hour",
      HOURS_SIZE: "Start hour must be smaller than end hour",
      IMAP: "IMAP",
      LAST_LOGINS: "Last logins",
      LOCAL_DELIV: "Local delivery",
      PERMISSIONS: "Permissions",
      POP: "POP3",
      SERVICE_PERM: "Service permissions",
      SMTP: "SMTP",
      SPAM_QUARANTINE: "Spam quarantine",
      UNRESTRICTED: "Unrestricted",
      UPDATE_HOURS: "Update access hours",
      UPDATE_PERM: "Update permissions",
      VACATION_MSG: "Vacation message",
      SEND_AS: "Send email as",
      CAN_SEND_AS: "Manage users that can send as ",
    },
    CC: "CC",
    DELEGATES: "Delegates",
    DESTINATION: "Destination",
    DETAILS: "User Details",
    FORWARD_TYPE: "Forward Type",
    FULL_PERMISSION_USERS: "Full Permission Users",
    MAILBOX_SUBTYPE: "Mailbox Subtype",
    NORMAL: "Normal",
    PERMISSIONS: "User Permissions",
    REDIRECT: "Redirect",
    ROOM: "Room",
    EQUIPMENT: "Equipment",
    SEND_AS: "Send As",
    SHARED: "Shared"
  },
  MIGRATE: {
    COMPLETE: "Complete",
    DEFAULT: "Default",
    DETAILS: "Migration Details",
    END_TIME: "End Time",
    ETA: "ETA",
    EXIT_MESSAGE: "Exit Message",
    EXIT_STATUS: "Exit Status",
    FOLDER: "Destination Folder",
    LOCAL_MIGRATION: "Local Migration",
    MIGRATE: "Migrate",
    MIGRATE_TO_FOLDER: "Migrate to Folder",
    NEW_MIGRATION: "New Migration",
    PROGRESS: "Progress",
    REFRESHING: "Refreshing every 10s",
    SCHEDULE: "Schedule",
    SECONDS_LEFT: "seconds left",
    STAGE: "Stage",
    START_TIME: "Start Time",
  },
  DOMAINS: {
    NEW_DOMAIN: "New Domain",
    DEL_DOMAIN: "Delete Domain",
    ADD_DOMAIN: "Add Domain",
    DOMAIN_NAME: "Domain Name",
    DOMAIN_TIMEZONE: "Domain Timezone",
    CATCHALL: "Catchall",
    WEBMAIL: "Webmail",
    EXC_ENABLED: "Exchange Enabled",
    LOCAL_DELIVERY: "Local Delivery",
    AUDIT_ENABLED: "Audit Enabled",
    EDIT_FOOTER: "Edit Footer ",
    FOOTER_DESC:
      "The message you enter below will be appended to all messages sent by all users.",
    OTHER: "Other",
    ENHANCED_GROUP_OFFICE: "Enhanced (Group-Office)",
    GROUP_OFFICE: "GroupOffice",
    NO_CATCHALL: "No Catchall",
    ACCEPT_DELETE: "Accept and Delete",
    NEW_ALIAS_DOMAIN: "New Alias Domain",
    ADD_ALIAS_DOMAIN: "Add Alias Domain",
    DEL_ALIAS_DOMAIN: "Delete Alias Domain",
    DKIM_SETTINGS: "DKIM Settings ",
    ENABLED: "Enabled",
    HOST: "Host",
    KEY: "Key",
    DOMAIN_TYPE: "Domain Type",
    VERIFYING: "Verifying...",
    VERIFY: "Verify",
    HOSTNAME: "Hostname",
    PLEASE_VERIFY: "Please verify domain ownership by adding this record to DNS:",
    OEX: "OEX",
    VERIFY_AGAIN: "Verify again",
    HEALTH: "Health",
    DOMAIN_HEALTH: "Domain health: ",
    CHECK_HEALTH_INFO: "Your domain is currently queued for verification. Please allow a few minutes for the process to complete.",
    MX_RECORDS: "MX records",
    CAA_RECORD: "CAA Record",
    WEBMAIL_RECORD: "Webmail record",
    WEBMAIL_SSL: "Webmail SSL",
    AUTODISCOVER_RECORD: "Autodiscover record",
    AUTODISCOVER_SSL: "Autodiscover SSL",
    SPF_RECORD: "SPF Record",
    OK: "OK",
    FAIL: "Fail",
    INSTALLED: "Installed",
    NOT_INSTALLED: "Not installed",
    FOUND: "Found",
    NOT_FOUND: "Not found",
    EXPL_MX: "If not properly set, emails won't be delivered to your domain",
    EXPL_CAA: "If not properly set, we cannot generate SSL certificates",
    EXPL_WEBMAIL: "If not properly set, webmail access for your domain will be restricted",
    EXPL_WEBMAIL_SSL: "If not properly set, connections to webmail might be insecure",
    EXPL_AUTODISCOVER: "If not properly set, email clients won't auto-configure, causing setup issues",
    EXPL_AUTODISCOVER_SSL: "If not properly set, autodiscover connections will be insecure, risking data exposure",
    EXPL_SPF: "If not properly set, your emails could be marked as spam or rejected",
  },
  ALIASES: {
    NEW_ALIAS: "New Alias",
    ADD_ALIAS: "Add Alias",
    DEL_ALIAS: "Delete Alias",
    UPDATE_ALIAS: "Update Alias",
    ALIAS: "Alias",
    FORWARDS_TO: "Forwards To",
    FORWARDS_DESTINATION: "Forward Destination",
    INTERNAL: "Internal",
    EXTERNAL: "External",
  },
  PUBLIC_FOLDERS: {
    ADD_FOLDER: "Add Folder",
    ADD_OWNER: "Add Owner",
    ALL: "All",
    AUTHOR: "Author",
    APPOINTMENT: "Appointment",
    CONTACT: "Contact",
    CONTAINER: "Container",
    CONTRIBUTOR: "Contributor",
    CREATE_ITEMS: "Create items",
    CREATE_SUBFOLDERS: "Create subfolders",
    DELETE_ITEMS: "Delete items",
    EDIT_ALL: "Edit all",
    EDIT_OWN: "Edit own",
    EDITOR: "Editor",
    FOLDER_CONTACT: "Folder contact",
    FOLDER_DETAILS: "Folder Details",
    FOLDER_NAME: "Folder name",
    FOLDER_OWNER: "Folder owner",
    FOLDER_VISIBLE: "Folder visible",
    FULL_DETAILS: "Full details",
    MAIL_AND_POST_ITEMS: "Mail and post items",
    NO_OWNERS: "No owners",
    NONE: "None",
    NONEDITING_AUTHOR: "Nonediting Author",
    OTHER: "Other",
    OWN: "Own",
    OWNER: "Owner",
    OWNERS: "Owners",
    PROFILE: "Profile",
    PUBLIC_FOLDER: "Public Folder",
    PUBLISHING_AUTHOR: "Publishing Author",
    PUBLISHING_EDITOR: "Publishing Editor",
    QUOTA: "Quota (GB)",
    READ: "Read",
    REVIEWER: "Reviewer",
    STICKY_NOTE: "Sticky note",
    TASK: "Task",
    USAGE: "Usage",
    WRITE: "Write",
  },
  AUDIT: {
    ENTRY: "Entry",
    AUDIT_EMAIL: "Audit email",
    INCOMING: "Incoming",
    OUTGOING: "Outgoing",
    AUDIT_TYPE: "Audit type",
    EMAIL_ADDRESS_DOMAIN: "Email Address or Domain",
    NO_AUDIT_DOMAIN: "No audit domain created. To enable audit for a domain, go to the Domain page.",
    NO_AUDIT_EMAIL: "No audit email created. To add a new Audit type mailbox, go to the User page.",
  },
  LISTS: {
    ADD_LIST: "Add List",
    NEW_LIST: "New List",
    DEL_LIST: "Delete List",
    ADD_MEMBER: "Add Member",
    MEMBER: "Member",
    MEMBER_NAME: "Member Name",
    LIST_NAME: "List Name",
    LIST_TYPE: "List Type",
    DISTRIBUTION_LIST: "Distribution List",
    SPAM_RETRAINER: "Spam Retrainer",
    NOT_SPAM_RETRAINER: "Not-Spam Retrainer",
    EXCHNAGE_ACCOUNT: "Exchange Account",
    EMAIL_ADDRESS: "Email Address",
    EMAIL_ADDRESS_DOMAIN: "Email Address or Domain",
    INTERNAL_MEMBERS: "Internal Members",
    EXTERNAL_MEMBERS: "External Members",
  },
  SMARTLISTS: {
    ADD_LIST: "Add Smart List",
    NEW_LIST: "New Smart List",
    DEL_LIST: "Delete Smart List",
    UPDATE_LIST: "Update Smart List",
    SPECIFY_MEMBERS: "Specify Members",
    ALL_DOMAIN_USERS: "All domain users",
    DG_TYPE: "List type",
    LIST_PROTECTION: "List security",
    ONLY_MEMBERS_POST: "Only members can post",
    PASSWORD_PROTECTED: "Password protected",
    NO_PROTECTION: "No protection",
    SEND_LABEL: "Send a copy of the message to the sender",
    MEMBER_MANAGEMENT: "Members Management",
    CAN_POST: "Can post",
    CAN_RECEIVE: "Receive list messages",
    MEMBERS_LIST: "Members List",
    MEMBERS: "Members",
    ALL_MEMBER_POST: "All members can post",
    MY_SUBJECT: "My Subject",
    PASSWORD_TXT:
      "When sending an email, enter this password at the beginning of the subject, in brackets.",
    EX: "EX",
  },
  FILTERING: {
    SETTINGS: {
      FILTER_SETTINGS: "Filter Settings",
      ENABLE_AUTOWHITELIST: "Enable Autowhitelist",
      FILTER_SENSITIVITY: "Filter Sensitivity",
      KEEP_SPAM_FOR: "Keep Spam For",
      SEND_SPAM_REPORT: "Send Spam Report",
      SPAM_REPORT_CONTENT: "Spam Report Content",
      SPAM_REPORT_FORMAT: "Spam Report Format",
      LOGIN_LOCATION_LIMIT: "Login Location Limit",
      NORMAL_SENSITIVITY: "Normal sensitivity",
      MOST_SPAM: "Most Spam gets through",
      MOST_MESSAGE: "Most messages are filtered",
      ALL_SPAM_MESSAGES: "All spam messages",
      LATEST_SPAM_MESSAGES: "Latest spam messages",
      HTML_TEXT: "HTML and TEXT",
      HTML_ONLY: "HTML only",
      TEXT_ONLY: "TEXT only",
      DONT_SEND: "Don't send",
      EVERY_HOUR: "Every 1 hour",
      EVERY_HOURS: "Every {num} hours",
    },
    WHITELIST: {
      IGNORE_SPF: "Ignore SPF",
      IGNORE_SPF_IP: "Ignore SPF IP",
      SENDER_IP: "Sender's IP address",
      IMPORT_DEC: "Please upload a file in CSV format, one address per line.",
      ADD_WHITELIST: "Add Whitelist",
      NEW_WHITELIST: "New Whitelist",
      DEL_WHITELIST: "Delete Whitelist",
      IMPORT: "Import",
      CHOOSE_PLACEHOLDER: "Choose a CSV or drop it here...",
      DROP_PLACEHOLDER: "Drop file here...",
      UPDATE_WHITELIST: "Update Whitelist",
      INCLUDE_USERS_WHITELIST: "Include Users Whitelist",
      APPLY_TO_HEADERS: "Apply to Headers",
    },
    BLACKLIST: {
      NEW_BLACKLIST: "New Blacklist",
      ADD_BLACKLIST: "Add Blacklist",
      DEL_BLACKLIST: "Delete Blacklist",
      UPDATE_BLACKLIST: "Update Blacklist",
      INCLUDE_USERS_BLACKLIST: "Include Users Blacklist",
    },
    AUTO_WHITELIST: {
      ADDRESS_LIKE: "Address Like",
      PICK_DATE: "Pick a Date",
    },
    IPACCESS_LISTS: {
      PREFERENCE: "Preference",
      SELECT_IPTYPE: "Select IP Type",
    },
  },
  BRANDING: {
    FORM_DESC: "These settings will apply to all users of the Admin.",
    FORM_DESC_DOMAINS: "These settings will apply to all users of the domain.",
    COMPANY_COLOR: "Company Color",
    COMPANY_NAME: "Company Name",
    COMPANY_WEBLINK: "Company Web Link",
    SUPPORT_EMAIL: "Support E-mail",
    COMPANY_LOGO: "Company Logo",
    DEFAULT_LOGO: "Default Logo",
    DEFAULT_LOGO_DESC: "Default Logo for Basic Group-Office interface",
    UPDATE_SETTINGS: "Update Settings",
    PREVIEW: "Preview",
    PREVIEW_LOGO: "Preview Logo",
    DOMAIN_LEVEL: "Domain Level Branding",
    RESET: "Reset to Admin defaults",
  },
  SETTINGS: {
    CHANGE_PASSWORD: "Change Password",
    CHNAGE_EMAIL: "Change Email",
    CHANGE_PASSWORD_DESC: "You can change your password",
    SYSTEM_TEXTS: "System Texts",
    QUOTA: "Quota Alert CC",
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm Password",
    CURRENT_EMAIL: "Current Email",
    NEW_EMAIL: "New Email",
    CHANGE_SYSTEXT: "Change System Texts",
    TEXT_FOR: "Text for",
    TEXT: "Text",
    TIMEOUT: "Admin Panel Timeout",
    MINUTES: "Minutes",
    CHANGE_QUOTA_ALERT: "Change Quota Alert",
    QUOTA_ALERT: "Quota Alert",
    CHANGE_ADMIN_LOCALE: "Change Admin Locale",
    CHANGE: "Change",
    FORGOT_PASSWORD_MAIL_TEXT: "Admin Mail Password Reset Text",
    FORGOT_PASSWORD_WEB_TEXT: "Admin Web Password Reset Text",
    QUOTA_WARNING_TEXT: "Quota Warning Text",
    QUOTA_WARNING_SUBJECT: "Quota Warning Subject",
    FORGOT_PASSWORD_MAIL_SUBJECT: "Admin Mail Reset Subject",
    PASSWORD_EXPIRY_WARNING_TEXT: "Password expiry warning text",
    PASSWORD_EXPIRY_WARNING_SUBJECT: "Password expiry warning subject",
    PASSWORD_EXPIRED_WARNING_TEXT: "Password expired warning text",
    PASSWORD_EXPIRED_WARNING_SUBJECT: "Password expired warning subject",
  },
  LOGS: {
    INCOMING_LOGS: {
      DELIVERED: "Delivered",
      UNDELIVERED: "Undelivered",
      DELIVERED_TO: "Delivered To",
      ENVELOPE_FROM: "Envelope From",
      TO: "To",
      SUBJECT: "Subject",
      IP: "IP",
      SPAM_SCORE: "Spam Score",
      DNSBL: "DNSBL",
      SPF: "SPF",
      DELIVERE: "Deliver",
      WHITELISTED: "Whitelisted",
      WHITELIST_DELIVER: "Whitelist & Deliver",
      HEADER_FROM: "Header From",
      REJECTED: "Rejected",
      DETAILS: "Details",
      MORE: " & {num} MORE",
    },
    OUTGOING_LOGS: {
      VIEW_MESSAGES: "View Messages",
      SENT_FROM: "Sent From",
      SENDER_IP: "Sender IP",
      SENT_TO: "Sent To",
      FROM_DATE: "From Date",
      TO_DATE: "To Date",
      DELIVERED: "Delivered",
      TEMPORARILY_REJECTED: "Temporarily Rejected",
      PERMANENTLY_REJECTED: "Permanently Rejected",
      ALL_MESSAGES: "All Messages",
      DESC: "This interface does not show same-domain deliveries.",
      RECIPIENT_STATUS: "Recipient delivery status",
      MESSAGE_STATUS: "Message delivery status",
    },
  },
  LAST_LOGINS: {
    START_DATE: "Start Date",
    END_DATE: "End Date",
    NEGATE_CONDITION: "Negate Condition",
    PASSWORD_USED: "Password used",
    SERVICE_USED: "Service used",
    RESET_SEARCH: "Reset Search",
    LOCATION: "Location",
    IMAP: "imap",
    POP3: "pop3",
    SMTP: "smtp",
    SIEVE: "sieve",
    LOGIN: "login",
    BLOCKED: "Blocked",
  },
  SUB_ADMINS: {
    ACCOUNT_HISTORY: "Account History",
    DGLISTS: "Smart Lists",
    NO_ACCESS: "No access",
    VIEW: "View",
    ENABLE_DISABLE: "Enable/Disable",
    REMOVE: "Remove",
    IMPERSONATE: "Impersonate",
    ADD_UPDATE: "Add/Update",
    MODIFY: "Modify",
    ADD_SUBADMIN: "Add Subadmin",
    DESC: "Higher level permissions include the ones before them",
  },
  SECURITY: {
    ACTION:
      "These users might have their account compromised. Change their password immediately and instruct the user to run an anti-virus scan.",
    ADMIN_ACCESS_INFO: "You must add at least one IP to the list",
    ALLOW_BUTTON: "Allow unrestricted Admin access",
    COUNTRIES: "Distinct Login Countries",
    ENABLED_COUNTRY: "Enabled for logins from more than {num} countries",
    INFO: "Click on the User to see his last logins.",
    IP: "IP",
    IP_DESC: "Why do you trust this IP: name of the location, or the service.",
    IP_BLOCKED: "IP is blocked",
    IP_NOT_BLOCKED: "IP is not blocked",
    MARK_SAFE: "Mark as Safe",
    MONITOR_LOGINS: "Monitor suspicious logins",
    NONE: "There were no security incidents detected in the last 24 hours.",
    RESTRICT_BUTTON: "Restrict Admin access only to the IPs in list",
    RESTRICT_STATUS_ACTIVE: "Admin access is restricted to only the IPs in this list",
    RESTRICT_STATUS_FREE: "Admin access is unrestricted",
    RESTRICT_WARNING: "Warning: make sure your IP is in the Admin IP Access List. Otherwise, you might lock yourself out.",
    RESTRICT: "Restrict",
    SECURITY_WARNING:
      "New security incidents detected. Click here to check the Security page.",
    SENT: "Emails sent",
    TYPE_DESC: "Choose if you wish to mark as safe the IP or the whole network",
    YOUR_IP: "Attention! Your current IP is {ip}, and is not in the Admin IP Access List. If you continue, you will lock yourself out!",
    VERIFY_IP: "Verify if IP is blocked",
  },
  VALIDATION: {
    REQUIRED_FIELD: "This is a required field.",
    DOMAIN_FIELD: "This is a required field and must be valid domain.",
    USERNAME_FIELD: "This is a required field and must be valid username.",
    ALIAS_FIELD: "This is a required field and must be valid alias.",
    EMAIL_FIELD: "This is a required email field.",
    EMAIL_DOMAIN_FIELD: "This is a required email or domain field.",
    QUOTA_FIELD: "Available quota is {num} GB.",
    QUOTA_ZERO: "No quota left.",
    REQUIRED_MINLENGTH_FIELD:
      "This is a required field and must be at least {num} characters.",
    IDENTICAL_FIELD: "This is a required field and must be identical.",
    IP_INVALID: "Invalid IP",
    FILL_ALL_FIELDS: "Please, fill in all fields.",
    MAX_LETTERS: "Must be at most {num} characters long.",
    MAX_VALUE: "This is a required field and must be less than {num}",
    MIN_LETTERS: "Must be at least {num} characters long.",
    REGEX: "Please match the accepted format: {rule}",
    NUMBERS: "numbers",
    LETTERS: "letters",
    LOWERCASE_LETTER: "Must contain a lowercase letter.",
    UPPERCASE_LETTER: "Must contain an uppercase letter.",
    NUMBER_LETTER: "Must contain a number.",
    SPECIAL_LETTER: "Must contain a special character.",
  },

  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
      WELCOME_ADMIN: "Welcome to Admin Control Panel!",
    },
    LOGIN: {
      TITLE: "Account Login",
      BUTTON: "Sign In",
      DESC1:
        "Welcome to our new Admin Panel! To access the legacy version, please visit: ",
      DESC2:
        "If you have 2FactorAuthentication enabled, add your 6 digits code at the end of your password",
      DESC3:
        "Get the code from the Twofa authenticator app on your mobile device and fill it in below.",
      DESC4:
        "There is no longer the need to append the Two Factor Authentication code to the password.",
      TFA_INVALID: "Invalid code or the code has expired",
      TFA: "Your code:",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      SEND: "Send",
      DESC:
      "If your username is the same as your e-mail address, please enter it twice.",
    },
    RESET_PASSWORD: {
      TITLE: "Resetting your Password...",
      DESC: "Click the button and we will generate a new password.",
      GENERATE_NEW_PASSWORD: "Generate New Password",
      GO_LOGIN: "Go to Login Page ",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      PASSWORD: "Password",
      USERNAME: "Username",
    },
    SIGN_OUT: "Sign Out",
    RESET: "Reset",
    VALIDATION: {
      EMAIL: "Email is required and a valid email address.",
      PASSWORD: "Password is required.",
      USERNAME: "Username is required.",
    },
  },
  ZULIP:{
    IS_ADMIN: "Is Admin",
    UPDATE: "Update Zulip User",
    ERROR_STATUS: "Error on status update",
    ZULIP_ACCOUNTS: "Zulip Accounts",
  },
};
