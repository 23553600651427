// Portugese
export const locale = {
  TRANSLATOR: {
    SELECT: "Selecione o idioma desejado.",
  },
  MENU: {
    ACTIONS: "Ações",
    CREATE_POST: "Criar nova postagem",
    PAGES: "Páginas",
    FEATURES: "Recursos",
    APPS: "Apps",
    HOME: "Início",
    SECURITY: "Segurança",
    SECURITY_INCIDENTS: "Incidentes de Segurança",
    TWOFA: "Autenticação Dupla",
    USERS: "Usuários",
    MIGRATIONS: "Migrações",						  
    DOMAINS: "Domínios",
    COMPLIANCE: "Auditoria",
    ALIASES: "Apelidos",
    LISTS: "Listas",
    FILTERING: "Filtragem",
    WHITELIST: "Lista branca",
    BLACKLIST: "Lista negra",
    AUTO_WHITELIST: "Autolista branca",
    ADMIN_IPACCESS_LISTS: "IPs confiáveis (Admin)",
    IPACCESS_LISTS: "IPs confiáveis/recusados (SMTP)",
    IPTRUSTED: "IPs de login de confiança do Usuário",
    BRANDING: "Customização",
    SETTINGS: "Configurações",
    LOGS: "Logs",
    INCOMING_LOGS: "Logs de Entrada",
    OUTGOING_LOGS: "Logs de Saída",
    LAST_LOGINS: "Últimos Acessos",
    ALIAS_DOMAINS: "Apelidos de Domínio",
    SMARTLISTS: "Listas Inteligentes",
    SUBADMINS: "Subadmins",
  },
  COMMON: {
    ACTION: "Ação",
    ADMIN: "Admin",
    DATE: "Data",
    DAYS_ARRAY: {
      1: "Segunda-feira",
      2: "Terça-feira",
      3: "Quarta-feira",
      4: "Quinta-feira",
      5: "Sexta-feira",
      6: "Sábado",
      7: "Domigo",
    },
    RPERPAGE: "Linha por página",
    ENABLED: "Habilitado",
    DISABLED: "Desabilitado",
    SAVE: "Salvar",
    STEP: "Passo",
    CANCEL: "Cancelar",
    CLOSE: "Fechar",
    RESET: "Limpar",
    NEXT: "Próximo",
    SEL_DOMAIN: "Selecione o Domínio",
	SEL_USER: "Selecione o Usuário",								 
    SEARCH: "Buscar",
    USERNAME: "Nome de usuário",
    USER_NAME: "Nome de usuário",
    DOMAIN: "Domínio",
    TYPE: "Tipo",
    RANGE: "Máscara de rede",
    PASSWORD: "Senha",
    QUOTA: "Cota (GB)",
    NEW: "Novo(a)",
    COPY: "Copiar",
    COPY_CLIPBOARD: "Copiar para a área de transferência",
    ADD: "Adicionar",
    LANGUAGE: "Idioma",
    LOCALIZATION: "Localização",
    DATE_FORMAT: "Formato de Data",
    UPDATE: "Atualizar ",
    DEL_WARNING:
      "Todos os dados serão perdidos! Você tem certeza que deseja remover?",
    DEL_ITEM_WARNING:
      "Todos os dados serão perdidos! Você tem certeza que deseja remover {item}?",
    DEL_CONFIRM: "Confirmação de exclusão",
	HOME_COUNTRY: "País de Residência",									 
	TRAVEL_MODE: "Modo viagem",						   
    TIMEZONE: "Fuso horário",
    ACTIVE: "Ativo",
    INACTIVE: "Inativo",
    YES: "Sim",
    NO: "Não",
    NO_DATA: "Não há dados disponíveis",
    ADDRESS: "Endereço",
    CHECK_SPAM: "Verificar por Spam",
    CHECK_VIRUS: "Verificar por Vírus",
    BROWSE: "Squeaky toy",
    DESCRIPTION: "Descrição",
    DELETE: "Excluir",
    EDIT: "Editar ",
    NOT_ALLOWED: "Não permitida",
    ALLOWED: "Permitida",
    DAY: " dia",
    DAYS: " dias",
	DESTINATION: "Destino",					   
    VALUE: "Valor",
    FOOTER: "Rodapé",
	MORE_DETAILS: "Maiores detalhes",								 
	OPTIONAL: "Opcional",					 
    OTPASSWORD: "Gerar senha temporária",
    OTHER_ADMIN: "Login como usuário",
    SEARCH_OPTIONS: "Opções de busca",
    SEARCH_RESULTS: "Resultados da busca (exclui entregas locais)",
	SOURCE: "Origem",				 
    STATUS: "Status",
    ADMIN_PANEL: "Painel de administração",
    SUCCESS: "Sucesso",
    ERROR: "Erro",
    SUCCESS_ALERT: "Operação bem sucedida!",
    ERROR_ALERT: "Operação falhou!",
    WARNING: "Alerta",
    OK: "Ok",
    HI: "Oi",
    TAB_LOGO: "Painel de administração",
    AUTO_LOGOUT: "Logout automático",
    ALL_DOMAINS: "Todos os Domínios",
    FULL_NAME: "Nome completo",
    WAIT_MESSAGE: "Por favor, aguarde 5 minutos para propagar as alterações",
    REGULAR: "Regular",
    REFRESH: "Atualizar"
  },
  AUTO_CLOSE: {
    TITLE: "Automatic Logout",
    DESC:
      "Não detectamos nenhuma atividade por quase 5 minutos. Deseja sair? Clique em Ok para sair e em Cancelar para permanecer logado.",
    TIME_REMAIN: "{sec} segundos para sair.",
  },
  HOME: {
    ADMIN_ACCOUNT: "Conta Admin",
    STATISTICS: "Estatísticas",
    REMOTE_IP: "IP Remoto",
    BASIC_ACCOUNTS: "Contas Básicas",
    ENHANCED_ACCOUNTS: "Contas Sync",
    DESC1:
      "Para maior segurança, considere ativar a autenticação dupla para as suas contas.",
    DESC2:
      "Basta instalar um aplicativo OTP, como <a href='https://www.authy.com/download'> &nbsp; Authy &nbsp; </a> e clicar em Autenticação Dupla no menu.",
    DESC3: "Autenticação de 2 fatores não ativada!",
  },
  TWOFA: {
    AUTHY: "Authy",
    TFAUTH: "Autenticação Dupla",
    SEL_STATUS: "Selecione o status",
    DEC1: "Verifique se você já instalou um aplicativo OTP, como",
    DEC2: "Na etapa 2, precisará escanear o QR Code com o App OTP.",
    DEC3: "Quando estiver pronto, clique no botão &#39;NEXT&#39; abaixo.",
    SCAN_DEC1:
      "Escaneie este código QR usando o seu app OTP (adicionando nova conta no mesmo).",
    SCAN_DEC2:
      'Uma vez que a conta tenha sido adicionada, você receberá um código ("token") de 6 dígitos.',
    SCAN_DEC3: "Insira o código no campo abaixo.",
    SCAN_DEC4:
      "Clique no botão &#39;SALVAR&#39; abaixo antes de seu código expirar.",
    CHALLENGE: "Desafio",
    DISABLE_TITLE: "Desabilitar Autenticação Dupla",
    DDEC1:
      "Para desabilitar a Autenticação Dupla, insira o código obtido no app. OTP (Authy).",
    DDEC2:
      "Clique no botão &#39;SALVAR&#39; abaixo antes de seu código expirar. Se isso acontecer, insira novamente o código recém-gerado do seu OTP App (Authy).",
  },
  USERS: {
    QUSAGE: "Cota e uso",
    EXPORT_RESULTS: "Exportar Resultados",
    NEW_USER: "Novo Usuário",
    DEL_USER: "Deletar usuário",
    ADD_USER: "Adicionar usuário",
    UPDATE_USER: "Atualizar usuário",
    PASS_DEC: "Favor garantir que tenha guardado sua senha",
    ACCOUNT_TYPE: "Tipo de Conta",
    ACCOUNT_TYPE_OEX: "Tipo de Conta OEX",
    NAME_OF_USER: "Nome Completo",
    FACTOR_AUTH: "Autenticação Dupla",
    ONETIME_TITLE: "Senha temporária criada.",
    ONETIME_SUCCESS: "Esta senha é válida por apenas 5 minutos!",
    BASIC: "Básica",
    ENHANCED: "Sync",
    NEVER: "Nunca",
    LAST_LOGIN: "Último Login",
    BASIC_ACCOUNT: "Conta Básica",
    ENHANCED_ACCOUNT: "Conta Sync",
    AUDIT_ACCOUNT: "Audit Account",
    DISABLE_SMTP_ONLY: "Somente SMTP",
    USER_QUOTA: "{num1}GB livre(s) de {num2}GB",
    USER_CONTROL_PANEL: "Painel do Usuário",
    EXCHANGE: "Conector Exchange",
    EXCHANGE_ACCOUNTS: "Conector Exchange",
    OFFICE365: "Conector External",
    OFFICE365_ACCOUNTS: "Conector External",
    MORE_OPTIONS: "Mais opções",
    LESS_OPTIONS: "Menos opções",
    ONLY_LOCAL_SMTP: "Somente envios locais",
    PERM: {
      ACCESS_HOURS: "Restringir acesso ao e-mail por horário",
      APP_PASS: "Senhas de aplicativos",
      CAN_ACCESS: "Pode acessar:",
      CHANGE_PASS: "Alterar a senha",
      DELIV_RULES: "Regras de entrega",
      DISABLED_INFO: "Usuários em vermelho significa que estão desativados ou têm restringido horas de login",
      EXTENSIONS: "Extensões",
      FOLDERS_CLEANUP: "Limpeza de pastas",
      FORWARDS: "Forwards",
      HOURS_BOTH: "Por favor, forneça ambos hora de início e hora final",
      HOURS_SIZE: "A hora de início deve ser menor que a hora de término",
      IMAP: "IMAP (inclui WebMail)",
      LAST_LOGINS: "Últimos logins",
      LOCAL_DELIV: "Entrega local",
      PERMISSIONS: "Permissões",
      UPDATE_HOURS: "Atualizar horário de acesso",
      POP: "POP3",
      SERVICE_PERM: "Permissões de serviço",
      SMTP: "SMTP",
      SPAM_QUARANTINE: "Quarentena de Spam",
      UNRESTRICTED: "Irrestrito",
      UPDATE_PERM: "Atualizar",
      VACATION_MSG: "Mensagem de férias",
      SEND_AS: "Enviar e-mail como",
      CAN_SEND_AS: "Gerenciar usuários que podem enviar como ",
    },
  },
  MIGRATE: {
    COMPLETE: "Finalizada",
    DEFAULT: "Padrão",
    DETAILS: "Detalhes da Migração",
    END_TIME: "Hora de término",
    ETA: "Hora prevista de chegada",
    EXIT_MESSAGE: "Mensagem de saída",
    EXIT_STATUS: "Status de saída",
    FOLDER: "Pasta de destino",
    LOCAL_MIGRATION: "Migração Local",
    MIGRATE: "Migrar",
    MIGRATE_TO_FOLDER: "Migrar para pasta",
    NEW_MIGRATION: "Nova migração",
    PROGRESS: "Progresso",
    REFRESHING: "Atualizando a cada 10s",
    SCHEDULE: "Programação",
    SECONDS_LEFT: "segundos restantes",
    STAGE: "Fase",
    START_TIME: "Hora de início",
  },			
  DOMAINS: {
    NEW_DOMAIN: "Novo Domínio",
    DEL_DOMAIN: "Excluir domínio",
    ADD_DOMAIN: "Adicionar domínio",
    DOMAIN_NAME: "Nome do domínio:",
    DOMAIN_TIMEZONE: "Fuso horário do Domínio:",
    CATCHALL: "Pega-tudo",
    WEBMAIL: "Webmail",
    EXC_ENABLED: "Exchange habilitado",
    LOCAL_DELIVERY: "Entrega Local",
    AUDIT_ENABLED: "Habilitar Auditoria",
    EDIT_FOOTER: "Editar Rodapé ",
    FOOTER_DESC:
      "A mensagem abaixo será agregada a todas as mensagens enviadas por todos os usuários.",
    OTHER: "Antigo (Afterlogic)",
    ENHANCED_GROUP_OFFICE: "Group-Office antigo",
    GROUP_OFFICE: "Group-Office novo",
    NO_CATCHALL: "Sem Pega-tudo",
    ACCEPT_DELETE: "Aceitar e Excluir",
    NEW_ALIAS_DOMAIN: "Novo Apelido de Domínio",
    ADD_ALIAS_DOMAIN: "Adicionar apelido de domínio",
    DEL_ALIAS_DOMAIN: "Excluir domínio de alias",
    DKIM_SETTINGS: "Configurações DKIM ",
    ENABLED: "Habilitado",
    HOST: "Host",
    KEY: "Chave",
    DOMAIN_TYPE: "Tipo de domínio",
    VERIFYING: "Verificando...",
    VERIFY: "Verificar",
    HOSTNAME: "Hostname",
    PLEASE_VERIFY: "Verifique a propriedade do domínio adicionando este registro ao DNS:",
    OEX: "OEX",
    VERIFY_AGAIN: "Verificar novamente",
    HEALTH: "Saúde",
    DOMAIN_HEALTH: "Saúde do domínio:",
    CHECK_HEALTH_INFO: "Seu domínio está atualmente na fila para verificação. Aguarde alguns minutos para a conclusão do processo.",
    MX_RECORDS: "Registros MX",
    CAA_RECORD: "Registro CAA",
    WEBMAIL_RECORD: "Registro Webmail",
    WEBMAIL_SSL: "Webmail SSL",
    AUTODISCOVER_RECORD: "Registro Autodiscover",
    AUTODISCOVER_SSL: "Autodiscover SSL",
    SPF_RECORD: "Registro SPF",
    OK: "OK",
    FAIL: "Falhar",
    INSTALLED: "Habilitado",
    NOT_INSTALLED: "Não instalado",
    FOUND: "Encontrado(s)",
    NOT_FOUND: "Não encontrado",
    EXPL_MX: "Se não estiver definido corretamente, os e-mails não serão entregues ao seu domínio",
    EXPL_CAA: "Se não estiver definido corretamente, não podemos gerar certificados SSL",
    EXPL_WEBMAIL: "Se não for definido corretamente, o acesso ao Webmail para o seu domínio será restrito",
    EXPL_WEBMAIL_SSL: "Se não for definido corretamente, as conexões com o Webmail podem ser inseguras",
    EXPL_AUTODISCOVER: "Se não estiver definido corretamente, os clientes de email não podem configurar automaticamente, causando problemas de configuração",
    EXPL_AUTODISCOVER_SSL: "Se não for definido corretamente, as conexões de descoberta automática serão inseguras, arriscando a exposição aos dados",
    EXPL_SPF: "Se não for definido corretamente, seus e-mails podem ser marcados como spam ou rejeitados",
  },
  ALIASES: {
    NEW_ALIAS: "Novo Apelido",
    ADD_ALIAS: "Adicionar apelido",
    DEL_ALIAS: "Excluir apelido",
    UPDATE_ALIAS: "Atualizar apelido",
    ALIAS: "Apelido",
    FORWARDS_TO: "Encaminha Para",
    FORWARDS_DESTINATION: "Destino",
    INTERNAL: "Interno",
    EXTERNAL: "Externo",
  },
  PUBLIC_FOLDERS: {
    ADD_FOLDER: "Adicionar pasta",
    ADD_OWNER: "Adicionar proprietário",
    ALL: "Todos",
    AUTHOR: "Autora",
    APPOINTMENT: "Encontro",
    CONTACT: "Contato",
    CONTAINER: "Recipiente",
    CONTRIBUTOR: "Contribuinte",
    CREATE_ITEMS: "Criar itens",
    CREATE_SUBFOLDERS: "Crie subpastas",
    DELETE_ITEMS: "Excluir itens",
    EDIT_ALL: "Editar tudo",
    EDIT_OWN: "Editar próprio",
    EDITOR: "Editora",
    FOLDER_CONTACT: "Contato da pasta",
    FOLDER_DETAILS: "Detalhes da pasta",
    FOLDER_NAME: "Nome da pasta",
    FOLDER_OWNER: "Proprietário da pasta",
    FOLDER_VISIBLE: "Pasta visível",
    FULL_DETAILS: "Detalhes completos",
    MAIL_AND_POST_ITEMS: "Correio e poste itens",
    NO_OWNERS: "Nenhum proprietário",
    NONE: "Nenhum",
    NONEDITING_AUTHOR: "Autor não editoso",
    OTHER: "Outra",
    OWN: "Ter",
    OWNER: "Proprietária",
    OWNERS: "os Proprietários",
    PROFILE: "Perfil",
    PUBLIC_FOLDER: "Pasta pública",
    PUBLISHING_AUTHOR: "Autor de publicação",
    PUBLISHING_EDITOR: "Editor de publicação",
    QUOTA: "Cota (GB)",
    READ: "Ler",
    REVIEWER: "Revisor",
    STICKY_NOTE: "Notas adesivas",
    TASK: "Tarefa",
    USAGE: "Uso",
    WRITE: "Escrever",
  },
  AUDIT: {
    ENTRY: "Entrada",
    AUDIT_EMAIL: "E -mail de auditoria",
    INCOMING: "Entrada",
    OUTGOING: "Saida",
    AUDIT_TYPE: "Tipo de auditoria",
    EMAIL_ADDRESS_DOMAIN: "Endereço de e -mail ou domínio",
    NO_AUDIT_DOMAIN: "Nenhum domínio de auditoria criado.Para ativar a auditoria para um domínio, vá para a página de domínio.",
    NO_AUDIT_EMAIL: "Nenhum email de auditoria criado.Para adicionar uma nova caixa de correio do tipo auditoria, vá para a página do usuário.",
  },
  LISTS: {
    ADD_LIST: "Adicionar lista",
    NEW_LIST: "Nova Lista",
    DEL_LIST: "Excluir lista",
    ADD_MEMBER: "Adicionar Membro",
    MEMBER: "Membro",
    MEMBER_NAME: "Nome do membro",
    LIST_NAME: "Nome da Lista",
    LIST_TYPE: "Tipo de Lista",
    DISTRIBUTION_LIST: "Lista de Distribuição",
    SPAM_RETRAINER: "Ensinamento Spam",
    NOT_SPAM_RETRAINER: "Ensinamento Não Spam",
    EXCHNAGE_ACCOUNT: "Conta Exchange",
    EMAIL_ADDRESS: "Endereço de e-mail",
    EMAIL_ADDRESS_DOMAIN: "E-mail ou domínio",
    INTERNAL_MEMBERS: "Membros internos",
    EXTERNAL_MEMBERS: "Membros externos",
  },
  SMARTLISTS: {
    ADD_LIST: "Adicionar Lista inteligente",
    NEW_LIST: "Nova lista inteligente",
    DEL_LIST: "Excluir lista inteligente",
    UPDATE_LIST: "Atualizar lista inteligente",
    SPECIFY_MEMBERS: "Especificar membros",
    ALL_DOMAIN_USERS: "Todos os usuários do domínio",
    DG_TYPE: "Tipo de lista",
    LIST_PROTECTION: "Segurança da lista",
    ONLY_MEMBERS_POST: "Somente membros podem postar",
    PASSWORD_PROTECTED: "Protegida por senha",
    NO_PROTECTION: "Sem proteção",
    SEND_LABEL: "Envie uma cópia da mensagem para remetente",
    MEMBER_MANAGEMENT: "Gerenciamento de membros",
    CAN_POST: "Pode postar",
    CAN_RECEIVE: "Receber mensagens da lista",
    MEMBERS_LIST: "Lista de Membros",
    MEMBERS: "Membros",
    ALL_MEMBER_POST: "Todos os membros podem postar",
    MY_SUBJECT: "Meu Assunto",
    PASSWORD_TXT:
      "Digite a senha no início do assunto, entre colchetes e sem espaços, seguida do assunto, também sem espaçamento antes dele.",
    EX: "Exemplo:",
  },
  FILTERING: {
    SETTINGS: {
      FILTER_SETTINGS: "Configurações de Filtragem",
      ENABLE_AUTOWHITELIST: "Habilitar autolista branca",
      FILTER_SENSITIVITY: "Sensibilidade",
      KEEP_SPAM_FOR: "Manter Spam por",
      SEND_SPAM_REPORT: "Enviar relatório de spam",
      SPAM_REPORT_CONTENT: "Conteúdo do Relatório de Spam",
      SPAM_REPORT_FORMAT: "Formato do Relatório de spam",
      LOGIN_LOCATION_LIMIT: "Limite de Locais de Login",
      NORMAL_SENSITIVITY: "Sensibilidade Normal",
      MOST_SPAM: "A maioria dos Spams é recebido",
      MOST_MESSAGE: "A maioria das mensagens é filtrada",
      ALL_SPAM_MESSAGES: "Todas as mensagens na pasta de spam",
      LATEST_SPAM_MESSAGES: "Apenas mensagens recentes na pasta de spam",
      HTML_TEXT: "HTML e TEXTO",
      HTML_ONLY: "Apenas HTML",
      TEXT_ONLY: "Apenas TEXTO",
      DONT_SEND: "Não enviar",
      EVERY_HOUR: "A cada 1 hora",
      EVERY_HOURS: "A cada {num} horas",
    },
    WHITELIST: {
      IGNORE_SPF: "Ignorar SPF",
      IGNORE_SPF_IP: "Ignorar IP SPF",
      SENDER_IP: "IP do remetente",
      IMPORT_DEC:
        "Favor enviar um arquivo no formato CSV, um endereço por linha.",
      ADD_WHITELIST: "Adicionar lista branca",
      NEW_WHITELIST: "Novo item",
      DEL_WHITELIST: "Excluir lista branca",
      IMPORT: "Importar",
      CHOOSE_PLACEHOLDER: "Escolha um CSV ou solte-o aqui ...",
      DROP_PLACEHOLDER: "Solte o arquivo aqui ...",
      UPDATE_WHITELIST: "Atualizar Lista branca",
      INCLUDE_USERS_WHITELIST: "Incluir lista branca dos usuários",
      APPLY_TO_HEADERS: "Aplicar a cabeçalhos",
    },
    BLACKLIST: {
      NEW_BLACKLIST: "Adicionar item",
      ADD_BLACKLIST: "Adicionar lista negra",
      DEL_BLACKLIST: "Excluir lista negra",
      UPDATE_BLACKLIST: "Atualizar Lista negra",
      INCLUDE_USERS_BLACKLIST: "Incluir lista negra dos usuários",
    },
    AUTO_WHITELIST: {
      ADDRESS_LIKE: "Endereço contém",
      PICK_DATE: "Escolha uma data",
    },
    IPACCESS_LISTS: {
      PREFERENCE: "Preferência",
      SELECT_IPTYPE: "Selecione o tipo de IP",
    },
  },
  BRANDING: {
    FORM_DESC:
      "Estas configurações serão aplicadas a todos os usuários do domínio.",
    COMPANY_COLOR: "Cor da empresa",
    COMPANY_NAME: "Nome da Empresa",
    COMPANY_WEBLINK: "Link Web da Empresa",
    SUPPORT_EMAIL: "E-mail do Suporte",
    COMPANY_LOGO: "Logo da Empresa",
    DEFAULT_LOGO: "Logotipo padrão",
    DEFAULT_LOGO_DESC: "Logo padrão para interface do Group-Office",
    UPDATE_SETTINGS: "Atualizar Configurações",
    PREVIEW: "Pré-visualizar",
    PREVIEW_LOGO: "Visualizar logotipo",
  },
  SETTINGS: {
    CHANGE_PASSWORD: "Alterar Senha do Painel",
    CHNAGE_EMAIL: "Alterar E-mail",
    CHANGE_PASSWORD_DESC: "Clique aqui para alterar sua senha",
    SYSTEM_TEXTS: "Textos do Sistema",
    QUOTA: "CC das Alertas de Cota",
    NEW_PASSWORD: "Nova Senha",
    CONFIRM_PASSWORD: "Confirmar Senha",
    CURRENT_EMAIL: "Email atual",
    NEW_EMAIL: "Novo E-mail",
    CHANGE_SYSTEXT: "Alterar Textos do Sistema",
    TEXT_FOR: "Texto para",
    TEXT: "Texto",
    TIMEOUT: "Duração da sessão do painel",
    MINUTES: "Minutos",
    CHANGE_QUOTA_ALERT: "Alterar Alerta de Cota",
    QUOTA_ALERT: "Alertas de Cota",
    CHANGE_ADMIN_LOCALE: "Alterar Localidade",
    CHANGE: "Alterar",
    FORGOT_PASSWORD_MAIL_TEXT:
      "Texto para Msg. de Reinicialização de Senha do Admin",
    FORGOT_PASSWORD_WEB_TEXT:
      "Texto web para Reinicialização de Senha do Admin",
    QUOTA_WARNING_TEXT: "Texto para Msg de Alerta de Cota",
    QUOTA_WARNING_SUBJECT: "Assunto para Msg de Alerta de Cota",
    FORGOT_PASSWORD_MAIL_SUBJECT:
      "Assunto para Msg. de Reinicialização de Senha do Admin",
    PASSWORD_EXPIRY_WARNING_TEXT: "Texto de alerta para expiração de senha",
    PASSWORD_EXPIRY_WARNING_SUBJECT:
      "Assunto para alerta de expiração de senha",
    PASSWORD_EXPIRED_WARNING_TEXT: "Texto para alerta de senha expirada",
    PASSWORD_EXPIRED_WARNING_SUBJECT: "Assunto para alerta de senha expirada",
  },
  LOGS: {
    INCOMING_LOGS: {
      DELIVERED: "Entregue",
      UNDELIVERED: "Não entregue",
      DELIVERED_TO: "Entregue para",
      ENVELOPE_FROM: "Remetente (Mail From)",
      TO: "Para",
      SUBJECT: "Assunto (Máx. de 3 palavras consecutivas)",
      IP: "IP",
      SPAM_SCORE: "Pontuação de spam",
      DNSBL: "DNSBL",
      SPF: "SPF",
      DELIVERE: "Entregar",
      WHITELISTED: "Lista branca",
      WHITELIST_DELIVER: "Lista branca & Entregar",
      HEADER_FROM: "Cabeçalho From",
      REJECTED: "Rejeitado",
      DETAILS: "Detalhes",
      MORE: " & MAIS {num}",
    },
    OUTGOING_LOGS: {
      VIEW_MESSAGES: "Visualizar Mensagens",
      SENT_FROM: "Enviado De",
      SENDER_IP: "IP do Remetente",
      SENT_TO: "Enviado Para",
      FROM_DATE: "Data De",
      TO_DATE: "Data Até",
      DELIVERED: "Entregues",
      TEMPORARILY_REJECTED: "Temporariamente Rejeitadas",
      PERMANENTLY_REJECTED: "Permanentemente Rejeitadas",
      ALL_MESSAGES: "Todas as Mensagens",
      DESC:
        "Esta interface não exibe entregas locais, entre contas do mesmo domínio.",
      RECIPIENT_STATUS: "Status de entrega ao Destinatário",
      MESSAGE_STATUS: "Status de entrega da mensagem",
    },
  },
  LAST_LOGINS: {
    START_DATE: "Data de início",
    END_DATE: "Data final",
    NEGATE_CONDITION: "Negar condição",
    PASSWORD_USED: "Senha Usada",
    SERVICE_USED: "Serviço Usado",
    RESET_SEARCH: "Reinicializar Busca",
    LOCATION: "Local",
    IMAP: "imap",
    POP3: "pop3",
    SMTP: "smtp",
    SIEVE: "sieve",
    LOGIN: "login",
    BLOCKED: "Bloqueado",
  },
  SUB_ADMINS: {
    ACCOUNT_HISTORY: "Histórico da conta",
    DGLISTS: "Listas Inteligentes",
    NO_ACCESS: "Negar acesso",
    VIEW: "Visualizar",
    ENABLE_DISABLE: "Habilitar / Desabilitar",
    REMOVE: "Remover",
    IMPERSONATE: "Personificar",
    ADD_UPDATE: "Adicionar / Atualizar",
    MODIFY: "Modificar",
    ADD_SUBADMIN: "Adicionar Subadmin",
    DESC: "As permissões de nível superior incluem as anteriores",
  },
  SECURITY: {
    ACTION:
      "Estes usuários podem ter tido suas contas comprometidas. Altere a senha imediatamente e instrua o usuário a executar uma varredura antivírus.",
    ADMIN_ACCESS_INFO: "Você deve adicionar pelo menos um IP à lista", 
    ALLOW_BUTTON: "Permitir acesso de administrador irrestrito",
    COUNTRIES: "Países de login distintos",
    ENABLED_COUNTRY: "Ativado para logins de mais de {num} países",
    INFO: "Clique no usuário para ver seus últimos logins.",
    IP: "IP",
    IP_DESC: "Por que você confia neste IP: nome do local ou do serviço.",
    IP_BLOCKED: "O endereço IP está bloqueado",
    IP_NOT_BLOCKED: "O endereço IP não está bloqueado",
    MARK_SAFE : "Marcar como seguro",
    MONITOR_LOGINS: "Monitorar logins suspeitos",
    NONE: "Não houve incidentes de segurança detectados nas últimas 24 horas.",
    RESTRICT_BUTTON: "Restringir o acesso de administrador apenas aos IPs na lista",
    RESTRICT_STATUS_ACTIVE: "O acesso de administrador é restrito apenas aos IPs nesta lista",
    RESTRICT_STATUS_FREE: "O acesso de administrador é irrestrito",
    RESTRICT_WARNING: "Aviso: certifique-se de que seu IP esteja na Lista de acesso de IP de administrador. Caso contrário, você pode se bloquear.",
    RESTRICT: "Restringir",
    SECURITY_WARNING:
      "Novos incidentes de segurança detectados. Clique aqui para verificar a página Segurança.",
    SENT: "Emails enviados",
    TYPE_DESC: "Escolha se deseja marcar como seguro o IP ou a rede inteira",
    YOUR_IP: "Atenção! Seu IP atual é {ip} e não está na lista de acesso de IP de administrador. Se você continuar, você ficará de fora!",
    VERIFY_IP: "Verifique se o IP está bloqueado",
  },
  VALIDATION: {
    REQUIRED_FIELD: "Este é um campo obrigatório.",
    DOMAIN_FIELD: "Este é um campo obrigatório e deve ser um domínio válido.",
    USERNAME_FIELD:
      "Este é um campo obrigatório e deve ser um nome de usuário válido.",
    ALIAS_FIELD: "This is a required field and must be valid alias.",
    EMAIL_FIELD: "Digite um e-mail ou domínio.",
    EMAIL_DOMAIN_FIELD: "Este é um campo de e-mail ou domínio obrigatório.",
    QUOTA_FIELD: "A cota disponível é de {num} GB.",
    QUOTA_ZERO: "Sem cota restante.",
    REQUIRED_MINLENGTH_FIELD:
      "Este é um campo obrigatório e deve ter pelo menos {num} caracteres.",
    IDENTICAL_FIELD: "Este é um campo obrigatório e deve ser idêntico.",
    IP_INVALID: "Endereço IP inválido",
    FILL_ALL_FIELDS: "Favor preencher todos os campos",
    MAX_VALUE: "Este é um campo obrigatório e deve ser menor que {num}",
    MAX_LETTERS: "Deve ter no máximo {num} caracteres.",
    MIN_LETTERS: "Deve ter pelo menos {num} caracteres.",
    REGEX: "Por favor, corresponda ao formato aceito: {rule}",
    NUMBERS: "números",
    LETTERS: "letras",
    LOWERCASE_LETTER: "Deve conter uma letra minúscula (a até z).",
    UPPERCASE_LETTER: "Deve conter uma letra maiúscula (A até Z).",
    NUMBER_LETTER: "Deve conter um número (0 a 9).",
    SPECIAL_LETTER: "Deve conter um caractere especial (!@#$%.)",
  },
  AUTH: {
    GENERAL: {
      OR: "Ou",
      SUBMIT_BUTTON: "Enviar",
      NO_ACCOUNT: "Não possui uma conta?",
      SIGNUP_BUTTON: "Inscrever-se",
      FORGOT_BUTTON: "Esqueceu a senha",
      BACK_BUTTON: "Costas",
      PRIVACY: "Privacidade",
      LEGAL: "Legal",
      CONTACT: "Contato",
      WELCOME_ADMIN:
        "Bem-vindo ao painel de controle de administração de e-mails.",
    },
    LOGIN: {
      TITLE: "Login da conta",
      BUTTON: "Entrar",
      DESC1:
        "Bem-vindo ao nosso novo Painel de Administração! Para acessar a versão antiga, visite: ",
      DESC2:
        "Se possui autenticação dupla ativada, adicione os 6 dígitos do código ao final da sua senha.",
      DESC3:
        "Obtenha o código do aplicativo autenticador Twofa em seu dispositivo móvel e preencha-o abaixo.",
      DESC4:
        "Não há mais a necessidade de anexar o código de autenticação de dois fatores à senha.",
      TFA_INVALID: "Código inválido ou o código expirou",
      TFA: "Seu código:",
    },
    FORGOT: {
      TITLE: "Esqueceu a senha?",
      SEND: "Enviar",
      DESC:
        "Favor informar o endereço de e-mail da conta administradora nas duas lacunas.",
    },
    RESET_PASSWORD: {
      TITLE: "Redefinindo sua senha...",
      DESC: "Clique no botão e geraremos uma nova senha.",
      GENERATE_NEW_PASSWORD: "Gerar nova senha",
      GO_LOGIN: "Ir para a página de login",
    },
    REGISTER: {
      TITLE: "Inscrever-se",
      DESC: "Digite seus detalhes para criar sua conta",
      SUCCESS: "Sua conta foi registrada com sucesso.",
    },
    INPUT: {
      EMAIL: "E-mail",
      PASSWORD: "Senha",
      USERNAME: "Nome de Usuário",
    },
    SIGN_OUT: "Sair",
    RESET: "Limpar",
    VALIDATION: {
      EMAIL: "O campo E-mail é obrigatório e precisa ser um endereço de e-mail válido.",
      PASSWORD: "Senha requerida.",
      USERNAME: "Nome de usuário é requerido.",
    },
  },
  ZULIP:{
    IS_ADMIN: "Is Admin",
    UPDATE: "Update Zulip User",
    ERROR_STATUS: "Error on status update",
    ZULIP_ACCOUNTS: "Zulip Accounts",
  },
};
