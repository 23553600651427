export const messages = {
  en: {
    $vuetify: {
      close: "Close",
      dataIterator: {
        pageText: "{0}-{1} of {2}",
        noResultsText: "No matching records found",
        loadingText: "Loading items...",
      },
      dataTable: {
        itemsPerPageText: "Rows per page:",
        ariaLabel: {
          sortDescending: ": Sorted descending. Activate to remove sorting.",
          sortAscending: ": Sorted ascending. Activate to sort descending.",
          sortNone: ": Not sorted. Activate to sort ascending.",
          activateAscending: "",
          activateDescending: "",
          activateNone: "",
        },
        sortBy: "Sort by",
      },
      dataFooter: {
        pageText: "{0}-{1} of {2}",
        itemsPerPageText: "Items per page:",
        itemsPerPageAll: "All",
        nextPage: "Next page",
        prevPage: "Previous page",
        firstPage: "First page",
        lastPage: "Last page",
      },
      datePicker: {
        itemsSelected: "{0} selected",
      },
      noDataText: "No data available",
      carousel: {
        prev: "Previous visual",
        next: "Next visual",
      },
      calendar: {
        moreEvents: "{0} more",
      },
    },
  },
  ptbr: {
    $vuetify: {
      close: "Fechar",
      dataIterator: {
        pageText: "{0}-{1} de {2}",
        noResultsText: "Nenhum registro correspondente encontrado",
        loadingText: "Carregando itens...",
      },
      dataTable: {
        itemsPerPageText: "Linhas por página:",
        ariaLabel: {
          sortDescending:
            ": Ordenado decrescente. Ativar para remover a classificação.",
          sortAscending:
            ": Ordenado ascendente. Ative para classificar em ordem decrescente.",
          sortNone:
            ": Ordenado ascendente. Ative para classificar em ordem decrescente.",
          activateAscending: "",
          activateDescending: "",
          activateNone: "",
        },
        sortBy: "Ordenar por",
      },
      dataFooter: {
        pageText: "{0}-{1} de {2}",
        itemsPerPageText: "Itens por página:",
        itemsPerPageAll: "Tudo",
        nextPage: "Próxima página",
        prevPage: "Página anterior",
        firstPage: "Primeira página",
        lastPage: "Última página",
      },
      datePicker: {
        itemsSelected: "{0} selecionada",
      },
      noDataText: "Não há dados disponíveis",
      carousel: {
        prev: "Visual anterior",
        next: "Próximo visual",
      },
      calendar: {
        moreEvents: "{0} Mais",
      },
    },
  },
  id: {
    $vuetify: {
      close: "Menutup",
      dataIterator: {
        pageText: "{0}-{1} dari {2}",
        noResultsText: "Tidak ada catatan yang cocok ditemukan",
        loadingText: "Memuat item...",
      },
      dataTable: {
        itemsPerPageText: "Baris per halaman:",
        ariaLabel: {
          sortDescending:
            ": Diurutkan menurun. Aktifkan untuk menghapus penyortiran.",
          sortAscending: ": Diurutkan naik. Aktifkan untuk mengurutkan turun.",
          sortNone: ": Tidak diurutkan. Aktifkan untuk mengurutkan naik.",
          activateAscending: "",
          activateDescending: "",
          activateNone: "",
        },
        sortBy: "Sortir dengan",
      },
      dataFooter: {
        pageText: "{0}-{1} dari {2}",
        itemsPerPageText: "Item per halaman:",
        itemsPerPageAll: "Semua",
        nextPage: "Halaman selanjutnya",
        prevPage: "Halaman sebelumnya",
        firstPage: "Halaman pertama",
        lastPage: "Halaman terakhir",
      },
      datePicker: {
        itemsSelected: "{0} terpilih",
      },
      noDataText: "Tidak ada data yang tersedia",
      carousel: {
        prev: "Visual sebelumnya",
        next: "Visual selanjutnya",
      },
      calendar: {
        moreEvents: "{0} lebih",
      },
    },
  },
  es: {
    $vuetify: {
      close: "Cerca",
      dataIterator: {
        pageText: "{0}-{1} de {2}",
        noResultsText: "No se encontraron registros coincidentes",
        loadingText: "Cargando artículos...",
      },
      dataTable: {
        itemsPerPageText: "Filas por página:",
        ariaLabel: {
          sortDescending:
            ": Ordenada descendiendo. Activar para eliminar la clasificación.",
          sortAscending:
            ": Ordenada ascendente. Activar para ordenar descendente.",
          sortNone: ": No clasificado. Activar para ordenar ascendente.",
          activateAscending: "",
          activateDescending: "",
          activateNone: "",
        },
        sortBy: "Sort by",
      },
      dataFooter: {
        pageText: "{0}-{1} de {2}",
        itemsPerPageText: "Artículos por página:",
        itemsPerPageAll: "Todas",
        nextPage: "Siguiente página",
        prevPage: "Pagina anterior",
        firstPage: "Primera página",
        lastPage: "Última página",
      },
      datePicker: {
        itemsSelected: "{0} seleccionado",
      },
      noDataText: "Datos no disponibles",
      carousel: {
        prev: "Visual previo",
        next: "Siguiente visual",
      },
      calendar: {
        moreEvents: "{0} mas",
      },
    },
  },
  fr: {
    $vuetify: {
      close: "Fermer",
      dataIterator: {
        pageText: "{0} - {1} sur {2}",
        noResultsText: "Aucun enregistrements correspondants trouvés",
        loadingText: "Chargement des éléments ...",
      },
      dataTable: {
        itemsPerPageText: "Lignes par page:",
        ariaLabel: {
          sortDescending: ": Tri décroissant. Activez pour supprimer le tri.",
          sortAscending:
            ": Tri croissant. Activez pour trier par ordre décroissant.",
          sortNone: ": Non trié. Activez pour trier par ordre croissant.",
          activateAscending: "",
          activateDescending: "",
          activateNone: "",
        },
        sortBy: "Trier par",
      },
      dataFooter: {
        pageText: "{0} - {1} sur {2}",
        itemsPerPageText: "Objets par page:",
        itemsPerPageAll: "Tout",
        nextPage: "Page suivante",
        prevPage: "Page précédente",
        firstPage: "Première page",
        lastPage: "Dernière page",
      },
      datePicker: {
        itemsSelected: "{0} sélectionné",
      },
      noDataText: "Aucune donn�es",
      carousel: {
        prev: "Visuel précédent",
        next: "Visuel suivant",
      },
      calendar: {
        moreEvents: "{0} plus",
      },
    },
  },
  de: {
    $vuetify: {
      close: "Schließen",
      dataIterator: {
        pageText: "{0} - {1} von {2}",
        noResultsText: "Keine übereinstimmenden Aufzeichnungen gefunden",
        loadingText: "Artikel laden ...",
      },
      dataTable: {
        itemsPerPageText: "Zeilen pro Seite:",
        ariaLabel: {
          sortDescending:
            ": Sortiert absteigend. Aktivieren Sie diese Option, um die Sortierung zu entfernen.",
          sortAscending:
            ": Aufsteigend sortiert. Aktivieren Sie diese Option, um absteigend zu sortieren.",
          sortNone:
            ": Nicht sortiert. Aktivieren Sie diese Option, um aufsteigend zu sortieren.",
          activateAscending: "",
          activateDescending: "",
          activateNone: "",
        },
        sortBy: "Sortieren nach",
      },
      dataFooter: {
        pageText: "{0} - {1} von {2}",
        itemsPerPageText: "Objekte pro Seite:",
        itemsPerPageAll: "Alle",
        nextPage: "Nächste Seite",
        prevPage: "Vorherige Seite",
        firstPage: "Erste Seite",
        lastPage: "Letzte Seite",
      },
      datePicker: {
        itemsSelected: "{0} ausgewählt",
      },
      noDataText: "Keine Daten verfügbar",
      carousel: {
        prev: "Vorheriges Bild",
        next: "Nächstes Bild",
      },
      calendar: {
        moreEvents: "{0} mehr",
      },
    },
  },
};
