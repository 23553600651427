import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";

// action types
// export const VERIFY_AUTH = "verifyAuth";
export const LOGIN_STEP1 = "loginStep1";
export const LOGIN_STEP2 = "loginStep2";
export const LOGOUT = "logout";
//export const REGISTER = "register";
//export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
//export const SET_AUTH = "setUser";
export const AUTH_SUCCESS = "authSuccess";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  // password login
  [LOGIN_STEP1](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("", credentials)
        .then(data => {
          if (data.returncode === 0 || !data.returndata.token) {
            context.commit(SET_ERROR, ["Invalid login name and/or password!"]);
          } else {
            if (!data.returndata.otprequired && data.returndata.token) {
              // no need for 2fa, login completed
              context.commit(AUTH_SUCCESS, data.returndata.token);
            }
            resolve(data);
          }
        })
        .catch(data => {
          context.commit(SET_ERROR, data);
        });
    });
  },
  // two factor auth check
  [LOGIN_STEP2](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("", credentials)
        .then(data => {
          if (data.returncode === 0 || !data.returndata) {
            context.commit(SET_ERROR, ["Invalid code"]);
          } else {
            if (data.returndata) {
              context.commit(AUTH_SUCCESS, data.returndata);
            }
          }
          // continue, to show the error
          resolve(data);
        })
        .catch(data => {
          context.commit(SET_ERROR, data);
        });
    });
  },
  [LOGOUT](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("", credentials)
        .then(data => {
          context.commit(PURGE_AUTH, data);
          resolve(data);
        })
        .catch(data => {
          context.commit(SET_ERROR, data);
        });
    });
  },
  // [REGISTER](context, credentials) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post("users", { user: credentials })
  //       .then(data => {
  //         context.commit(SET_AUTH, data);
  //         resolve(data);
  //       })
  //       .catch(data => {
  //         context.commit(SET_ERROR, data);
  //         reject(data);
  //       });
  //   });
  // },
  // [VERIFY_AUTH](context) {
  //   if (JwtService.getToken()) {
  //     return true;
  //     // ApiService.setHeader();
  //     // ApiService.get("verify")
  //     //   .then(({ data }) => {
  //     // context.commit(SET_AUTH, data);
  //     // })
  //     // .catch(({ response }) => {
  //     //   context.commit(SET_ERROR, response.data.errors);
  //     // });
  //   } else {
  //     const data = { returndata: "success" };
  //     context.commit(PURGE_AUTH, data);
  //   }
  // },
  // [UPDATE_USER](context, payload) {
  //   const { email, username, password, image, bio } = payload;
  //   const user = { email, username, bio, image };
  //   if (password) {
  //     user.password = password;
  //   }

  //   return ApiService.put("user", user).then(({ data }) => {
  //     context.commit(SET_AUTH, data);
  //     return data;
  //   });
  // },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  // [SET_AUTH](state, user) {
  //   if (user.returndata) {
  //     state.isAuthenticated = true;
  //     state.user = user;
  //     state.errors = {};
  //     JwtService.saveToken(state.user.returndata);
  //   } else {
  //     // const errorMessage = ""
  //     state.errors = ["Invalid login name and/or password!"];
  //   }
  // },
  [AUTH_SUCCESS](state, token) {
    if (token) {
      state.isAuthenticated = true;
      state.user = token;
      state.errors = {};
      JwtService.saveToken(token);
    } else {
      // const errorMessage = ""
      state.errors = ["Login failed"];
    }
  },
  [PURGE_AUTH](state, data) {
    if (data.returndata == "success") {
      state.isAuthenticated = false;
      state.user = {};
      state.errors = {};
      JwtService.destroyToken();
      let lang = localStorage.language;
      localStorage.clear();
      localStorage.setItem("language", lang);
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
