import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/common/config/i18n/en";
import { locale as id } from "@/common/config/i18n/id";
import { locale as ptbr } from "@/common/config/i18n/pt-br";
import { locale as es } from "@/common/config/i18n/es";
import { locale as de } from "@/common/config/i18n/de";
import { locale as fr } from "@/common/config/i18n/fr";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, id, ptbr, es, de, fr };

let defaultLanguage = "en";
if (navigator.languages != undefined) {
  const langCode = navigator.languages[0].slice(0, 2);
  if (langCode == "es") {
    defaultLanguage = "es";
  } else if (langCode == "pt") {
    defaultLanguage = "ptbr";
  } else if (langCode == "id") {
    defaultLanguage = "id";
  } else if (langCode == "de") {
    defaultLanguage = "de";
  } else if (langCode == "fr") {
    defaultLanguage = "fr";
  }
}
// get current selected language
const lang = localStorage.getItem("language") || defaultLanguage;

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
});

export default i18n;
