import ApiService from "@/common/api.service";
// import JwtService from "@/common/jwt.service";

// action types
export const SETSTATUS = "setStatus";

// mutation types
export const SET_STATUS = "setStatus";
export const SET_ERROR = "setError";

const state = {
  basicAccount: localStorage.basic_account,
  enhancedAccount: localStorage.enhanced_account,
  zulipAccount: localStorage.zulip_account,
  domains: localStorage.domains,
  exchange: localStorage.exchange,
  hasExchange: localStorage.hasExchange,
  office365: localStorage.office365,
  hasOffice: localStorage.hasOffice,
  hasZulip: localStorage.hasZulip,
  quota: localStorage.quota,
};

const getters = {
  currentStatus(state) {
    return (
      state.basicAccount, state.enhancedAccount, state.zulipAccount, state.domains, state.quota, state.hasExchange, state.hasOffice, state.hasZulip
    );
  },
};

const actions = {
  [SETSTATUS](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("", credentials)
        .then((data) => {
          context.commit(SET_STATUS, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_ERROR, data);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_STATUS](state, res) {
    if (res.returncode) {
      const basicAccount =
        res.returndata.curemails + " / " + res.returndata.maxemails;
      const enhancedAccount =
        res.returndata.cureemails + " / " + res.returndata.maxeemails;
      const domains =
        res.returndata.curdomains + " / " + res.returndata.maxdomains;
      const exchange =
        res.returndata.curexemails + " / " + res.returndata.maxexemails;
      const hasExchange =
        res.returndata.maxexemails == 0 ? false : true;
      const office365 =
        res.returndata.curocemails + " / " + res.returndata.maxocemails;
      const hasOffice =
        res.returndata.maxocemails == 0 ? false : true;
      const hasZulip =
        res.returndata.maxzulipusers == 0 ? false : true;
      const quota =
        res.returndata.curquota / 1000 + " / " + res.returndata.maxquota / 1000;
      let limitQuota = 0;
      if (res.returndata.autogrow_quota == 1) {
        limitQuota = res.returndata.maxquota / 1000;
      } else {
        limitQuota =
          res.returndata.maxquota / 1000 - res.returndata.curquota / 1000;
      }
      const zulipAccount =
        res.returndata.curzulipusers + " / " + res.returndata.maxzulipusers;

      window.localStorage.setItem("basic_account", basicAccount);
      window.localStorage.setItem("enhanced_account", enhancedAccount);
      window.localStorage.setItem("domains", domains);
      window.localStorage.setItem("exchange", exchange);
      window.localStorage.setItem("hasExchange", hasExchange);
      window.localStorage.setItem("office365", office365);
      window.localStorage.setItem("hasOffice", hasOffice);
      window.localStorage.setItem("hasZulip", hasZulip);
      window.localStorage.setItem("quota", quota);
      window.localStorage.setItem("limitQuota", limitQuota);
      window.localStorage.setItem("zulip_account", zulipAccount);
      state.basicAccount = basicAccount;
      state.enhancedAccount = enhancedAccount;
      state.domains = domains;
      state.exchange = exchange;
      state.hasExchange = hasExchange;
      state.office365 = office365;
      state.hasOffice = hasOffice;
      state.hasZulip = hasZulip;
      state.quota = quota;
      state.zulipAccount = zulipAccount;
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
