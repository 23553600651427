// Indonesia
export const locale = {
  TRANSLATOR: {
    SELECT: "Pilih bahasamu.",
  },
  MENU: {
    ACTIONS: "Tindakan",
    CREATE_POST: "Buat Posting Baru",
    PAGES: "Halaman",
    FEATURES: "fitur",
    APPS: "Aplikasi",
    HOME: "Rumah",
    SECURITY: "Keamanan",
    SECURITY_INCIDENTS: "Insiden Keamanan",
    TWOFA: "2FA",
    USERS: "User",
    MIGRATIONS: "Migrations",
    DOMAINS: "Domain",
    COMPLIANCE: "Compliance",
    ALIASES: "Alias",
    LISTS: "Daftar",
    FILTERING: "Penyaringan",
    WHITELIST: "Daftar Terpercaya",
    BLACKLIST: "Daftar Tidak Terpercaya",
    AUTO_WHITELIST: "Daftar Putih Otomatis",
    ADMIN_IPACCESS_LISTS: "Daftar Akses IP Admin",
    IPACCESS_LISTS: "Daftar Akses IP",
    IPTRUSTED: "IP Login Tepercaya Pengguna",
    BRANDING: "Branding",
    SETTINGS: "Pengaturan",
    LOGS: "Log",
    INCOMING_LOGS: "Log Masuk",
    OUTGOING_LOGS: "lacak Pengiriman",
    LAST_LOGINS: "Login Terakhir",
    ALIAS_DOMAINS: "Alias Domain",
    SMARTLISTS: "Smart Lists",
    SUBADMINS: "Sub-Admins",
  },
  COMMON: {
    ACTION: "Kegiatan",
    ADMIN: "Admin",
    DATE: "Tanggal",
    DAYS_ARRAY: {
      1: "Senin",
      2: "Selasa",
      3: "Rabu",
      4: "Kamis",
      5: "Jumat",
      6: "Sabtu",
      7: "Minggu",
    },
    RPERPAGE: "Baris per halaman",
    ENABLED: "Gunakan",
    DISABLED: "Dengan disabilitas",
    SAVE: "Menyimpan",
    STEP: "Langkah",
    CANCEL: "Membatalkan",
    CLOSE: "Menutup",
    RESET: "Setel ulang",
    NEXT: "Lanjut",
    SEL_DOMAIN: "Pilih Domain",
    SEL_USER: "Select User",
    SEARCH: "cari",
    USERNAME: "Nama pengguna",
    USER_NAME: "Nama pengguna",
    DOMAIN: "Domain",
    TYPE: "Jenis",
    RANGE: "Topeng jaringan",
    PASSWORD: "Kata sandi",
    QUOTA: "Quote (GB)",
    NEW: "Baru",
    COPY: "Salinan",
    COPY_CLIPBOARD: "Menyalin ke clipboard",
    ADD: "Menambahkan",
    LANGUAGE: "Bahasa",
    LOCALIZATION: "Lokalisasi",
    DATE_FORMAT: "Format tanggal",
    UPDATE: "Memperbarui",
    DEL_WARNING: "Semua data akan hilang! Anda yakin ingin menghapus?",
    DEL_ITEM_WARNING:
      "All data will be lost! Are you sure you want to remove {item}?",
    DEL_CONFIRM: "Hapus Konfirmasi",
    HOME_COUNTRY: "Negara tempat tinggal",
    TRAVEL_MODE: "Modus Perjalanan",
    TIMEZONE: "Zona waktu",
    ACTIVE: "Aktif",
    INACTIVE: "Tidak aktif",
    YES: "Ya",
    NO: "Tidak",
    NO_DATA: "Tidak ada data yang tersedia",
    ADDRESS: "Alamat",
    CHECK_SPAM: "Periksa Spam",
    CHECK_VIRUS: "Periksa Virus",
    BROWSE: "Telusuri",
    DESCRIPTION: "Deskripsi",
    DELETE: "Hapus",
    EDIT: "Edit",
    NOT_ALLOWED: "Tidak diizinkan",
    ALLOWED: "Diizinkan",
    DAY: "hari",
    DAYS: "hari",
    DESTINATION: "Destination",
    VALUE: "Nilai",
    FOOTER: "Footer",
    MORE_DETAILS: "More details",
    OPTIONAL: "Optional",
    OTPASSWORD: "Kata sandi OTP",
    OTHER_ADMIN: "Admin lainnya",
    SEARCH_OPTIONS: "Pilihan Pencarian",
    SEARCH_RESULTS: "hasil pencarian",
    SOURCE: "Source",
    STATUS: "Status",
    ADMIN_PANEL: "panel admin",
    SUCCESS: "Berhasil",
    ERROR: "Kesalahan",
    SUCCESS_ALERT: "Operasi berhasil!",
    ERROR_ALERT: "Operasi telah diajukan!",
    WARNING: "Peringatan",
    OK: "Baik",
    HI: "Hai",
    TAB_LOGO: "Panel Admin",
    AUTO_LOGOUT: "Logout Otomatis",
    ALL_DOMAINS: "Semua Domain",
    FULL_NAME: "Nama lengkap",
    WAIT_MESSAGE: "Mohon tunggu 5 menit untuk menyebarkan perubahan",
    REGULAR: "Reguler",
    REFRESH: "Muat ulang"
  },
  AUTO_CLOSE: {
    TITLE: "Automatic Logout",
    DESC:
      "Kami tidak mendeteksi aktivitas apa pun selama hampir 5 menit. Tetap masuk?",
    TIME_REMAIN: "{detik} detik untuk keluar.",
  },
  HOME: {
    ADMIN_ACCOUNT: "Akun Admin",
    STATISTICS: "Statistik",
    REMOTE_IP: "IP jarak jauh",
    BASIC_ACCOUNTS: "Akun Dasar",
    ENHANCED_ACCOUNTS: "Akun Tingkat Lanjut",
    DESC1:
      "Untuk meningkatkan keamanan, pertimbangkan untuk mengaktifkan 2 Factor Authentication untuk akun ini.",
    DESC2:
      'Cukup instal Aplikasi OTP seperti <a href="https://www.authy.com/download">Authy</a> dan klik 2FA di menu.',
    DESC3: "Otentikasi 2 faktor tidak diaktifkan!",
  },
  TWOFA: {
    AUTHY: "Authy",
    TFAUTH: "Otentikasi Dua Faktor",
    SEL_STATUS: "Pilih Status",
    DEC1: "Pastikan Anda sudah menginstal Aplikasi OTP, seperti",
    DEC2:
      "Pada langkah 2, Anda harus memindai Kode QR menggunakan Aplikasi OTP.",
    DEC3: "Saat Anda siap, tekan tombol &#39;BERIKUTNYA&#39; di bawah.",
    SCAN_DEC1: "Pindai kode QR ini menggunakan Aplikasi OTP Anda.",
    SCAN_DEC2: "Setelah akun ditambahkan, Anda akan diberikan kode 6 digit.",
    SCAN_DEC3: "Masukkan kode di bidang di bawah ini.",
    SCAN_DEC4:
      "Tekan tombol &#39;SIMPAN&#39; di bawah sebelum kode Anda kedaluwarsa.",
    CHALLENGE: "Tantangan",
    DISABLE_TITLE: "Nonaktifkan Otentikasi Dua Faktor",
    DDEC1:
      "Untuk menonaktifkan 2 Factor Authentication, masukkan kode dari Aplikasi OTP Anda (Authy).",
    DDEC2:
      "Tekan tombol &#39;SIMPAN&#39; di bawah sebelum kode Anda kedaluwarsa. Jika ini terjadi, masukkan lagi kode Anda yang baru dibuat dari Aplikasi OTP Anda (Authy).",
  },
  USERS: {
    QUSAGE: "Kuota &amp; Penggunaan",
    EXPORT_RESULTS: "Hasil Ekspor",
    NEW_USER: "User Baru",
    DEL_USER: "Hapus pengguna",
    ADD_USER: "Tambahkan pengguna",
    UPDATE_USER: "Perbarui Pengguna",
    PASS_DEC: "Harap pastikan untuk menyimpan kata sandi ini.",
    ACCOUNT_TYPE: "Jenis Akun",
    ACCOUNT_TYPE_OEX: "OEX Jenis Akun",
    NAME_OF_USER: "Nama Pengguna",
    FACTOR_AUTH: "2Faktor Auth",
    ONETIME_TITLE: "Kata sandi satu kali dibuat!",
    ONETIME_SUCCESS: "Kata sandi ini hanya berlaku selama 5 menit!",
    BASIC: "Dasar",
    ENHANCED: "Tingkat lanjut",
    NEVER: "Tidak pernah",
    LAST_LOGIN: "Login terakhir",
    BASIC_ACCOUNT: "Akun Dasar",
    ENHANCED_ACCOUNT: "Akun Tingkat Lanjut",
    AUDIT_ACCOUNT: "Audit Account",
    DISABLE_SMTP_ONLY: "Nonaktifkan SMTP saja",
    USER_QUOTA: "{num1}GB free of {num2}GB",
    USER_CONTROL_PANEL: "Panel Kontrol Pengguna",
    EXCHANGE: "Exchange konektor",
    EXCHANGE_ACCOUNTS: "Exchange konektor",
    OFFICE365: "External konektor",
    OFFICE365_ACCOUNTS: "External konektor",
    MORE_OPTIONS: "Lebih banyak pilihan",
    LESS_OPTIONS: "Opsi yang Lebih Sedikit",
    ONLY_LOCAL_SMTP: "Only Local SMTP",
    PERM: {
      ACCESS_HOURS: "Akses email hanya di antara jam-jam tertentu",
      APP_PASS: "Kata sandi aplikasi",
      CAN_ACCESS: "Dapat mengakses:",
      CHANGE_PASS: "Ganti kata sandi",
      DELIV_RULES: "Aturan pengiriman",
      DISABLED_INFO: "Pengguna dengan warna merah baik dinonaktifkan atau mereka telah membatasi jam masuk",
      EXTENSIONS: "Ekstensi",
      FOLDERS_CLEANUP: "Pembersihan folder",
      FORWARDS: "Forwards",
      HOURS_BOTH: "Harap berikan baik jam awal dan akhir jam",
      HOURS_SIZE: "Mulai jam harus lebih kecil dari akhir jam",
      IMAP: "IMAP",
      LAST_LOGINS: "Login terakhir",
      LOCAL_DELIV: "Pengiriman lokal",
      PERMISSIONS: "Izin",
      POP: "POP3",
      SERVICE_PERM: "Izin layanan",
      SMTP: "SMTP",
      SPAM_QUARANTINE: "Spam karantina",
      UNRESTRICTED: "Tidak dibatasi",
      UPDATE_HOURS: "Perbarui jam akses",
      UPDATE_PERM: "Memperbarui",
      VACATION_MSG: "Pesan liburan",
      SEND_AS: "Kirim email sebagai",
      CAN_SEND_AS: "Kelola pengguna yang dapat mengirim sebagai ",
    },
  },
  MIGRATE: {
    COMPLETE: "Complete",
    DEFAULT: "Default",
    DETAILS: "Migration Details",
    END_TIME: "End Time",
    ETA: "ETA",
    EXIT_MESSAGE: "Exit Message",
    EXIT_STATUS: "Exit Status",
    FOLDER: "Destination Folder",
    LOCAL_MIGRATION: "Local Migration",
    MIGRATE: "Migrate",
    MIGRATE_TO_FOLDER: "Migrate to Folder",
    NEW_MIGRATION: "New Migration",
    PROGRESS: "Progress",
    REFRESHING: "Refreshing every 10s",
    SCHEDULE: "Schedule",
    SECONDS_LEFT: "seconds left",
    STAGE: "Stage",
    START_TIME: "Start Time",
  },
  DOMAINS: {
    NEW_DOMAIN: "Domain Baru",
    DEL_DOMAIN: "Hapus Domain",
    ADD_DOMAIN: "Tambahkan domain",
    DOMAIN_NAME: "Nama Domain:",
    DOMAIN_TIMEZONE: "Zona Waktu Domain:",
    CATCHALL: "Catchall",
    WEBMAIL: "Webmail",
    EXC_ENABLED: "Pertukaran Diaktifkan",
    LOCAL_DELIVERY: "Local Delivery",
    AUDIT_ENABLED: "Audit Enabled",
    EDIT_FOOTER: "Edit Footer",
    FOOTER_DESC:
      "Pesan yang Anda masukkan di bawah ini akan ditambahkan ke semua pesan yang dikirim oleh semua pengguna.",
    OTHER: "Lain",
    ENHANCED_GROUP_OFFICE: "Ditingkatkan (Grup-Kantor)",
    GROUP_OFFICE: "Kantor Grup",
    NO_CATCHALL: "Tanpa Catchall",
    ACCEPT_DELETE: "Terima dan Hapus",
    NEW_ALIAS_DOMAIN: "Alias Domain Baru",
    ADD_ALIAS_DOMAIN: "Tambahkan alias domain",
    DEL_ALIAS_DOMAIN: "Hapus Domain Alias",
    DKIM_SETTINGS: "Pengaturan DKIM",
    ENABLED: "Diaktifkan",
    HOST: "Daftar",
    KEY: "Kunci",
    DOMAIN_TYPE: "Tipe domain",
    VERIFYING: "Memverifikasi...",
    VERIFY: "Memeriksa",
    HOSTNAME: "Hostname",
    PLEASE_VERIFY: "Harap verifikasi kepemilikan domain dengan menambahkan catatan ini ke DNS:",
    OEX: "OEX",
    VERIFY_AGAIN: "Verifikasi lagi",
    HEALTH: "Kesehatan",
    DOMAIN_HEALTH: "Kesehatan domain:",
    CHECK_HEALTH_INFO: "Domain Anda saat ini sedang dalam antrean untuk verifikasi. Harap tunggu beberapa menit untuk menyelesaikan prosesnya.",
    MX_RECORDS: "Catatan MX",
    CAA_RECORD: "Catatan CAA",
    WEBMAIL_RECORD: "Catatan Webmail",
    WEBMAIL_SSL: "Webmail SSL",
    AUTODISCOVER_RECORD: "Catatan Autodiscover",
    AUTODISCOVER_SSL: "Autodiscover SSL",
    SPF_RECORD: "Catatan SPF",
    OK: "OKE",
    FAIL: "Gagal",
    INSTALLED: "Terpasang",
    NOT_INSTALLED: "Tidak terpasang",
    FOUND: "Ditemukan",
    NOT_FOUND: "Tidak ditemukan",
    EXPL_MX: "Jika tidak diatur dengan benar, email tidak akan dikirim ke domain Anda",
    EXPL_CAA: "Jika tidak diatur dengan benar, kami tidak dapat menghasilkan sertifikat SSL",
    EXPL_WEBMAIL: "Jika tidak diatur dengan benar, akses Webmail untuk domain Anda akan dibatasi",
    EXPL_WEBMAIL_SSL: "Jika tidak diatur dengan benar, koneksi ke Webmail mungkin tidak aman",
    EXPL_AUTODISCOVER: "Jika tidak diatur dengan benar, klien email tidak akan mengkonfigurasi secara otomatis, menyebabkan masalah pengaturan",
    EXPL_AUTODISCOVER_SSL: "Jika tidak diatur dengan benar, koneksi Autodiscover akan tidak aman, mengambil risiko paparan data",
    EXPL_SPF: "Jika tidak diatur dengan benar, email Anda dapat ditandai sebagai spam atau ditolak",
  },
  ALIASES: {
    NEW_ALIAS: "Alias baru",
    ADD_ALIAS: "Tambahkan alias",
    DEL_ALIAS: "Hapus Alias",
    UPDATE_ALIAS: "Perbarui Alias",
    ALIAS: "Alias",
    FORWARDS_TO: "Teruskan ke",
    FORWARDS_DESTINATION: "Tujuan",
    INTERNAL: "Internal",
    EXTERNAL: "Eksternal",
  },
  PUBLIC_FOLDERS: {
    ADD_FOLDER: "Tambah Folder",
    ADD_OWNER: "Tambahkan pemilik",
    ALL: "Semua",
    AUTHOR: "Pengarang",
    APPOINTMENT: "Janji temu",
    CONTACT: "Kontak",
    CONTAINER: "Wadah",
    CONTRIBUTOR: "Penyumbang",
    CREATE_ITEMS: "Buat item",
    CREATE_SUBFOLDERS: "Buat subfolder",
    DELETE_ITEMS: "Hapus item",
    EDIT_ALL: "Edit semua",
    EDIT_OWN: "Edit sendiri",
    EDITOR: "editor",
    FOLDER_CONTACT: "Kontak folder",
    FOLDER_DETAILS: "Detail folder",
    FOLDER_NAME: "Nama folder",
    FOLDER_OWNER: "Pemilik folder",
    FOLDER_VISIBLE: "Folder terlihat",
    FULL_DETAILS: "Detail lengkap",
    MAIL_AND_POST_ITEMS: "Surat dan Posting Item",
    NO_OWNERS: "Tidak ada pemilik",
    NONE: "Tidak ada",
    NONEDITING_AUTHOR: "Penulis non tedit",
    OTHER: "Lainnya",
    OWN: "Memiliki",
    OWNER: "Pemilik",
    OWNERS: "Pemilik",
    PROFILE: "Profil",
    PUBLIC_FOLDER: "Folder publik",
    PUBLISHING_AUTHOR: "Penulis Penerbitan",
    PUBLISHING_EDITOR: "Editor Penerbitan",
    QUOTA: "Kuota (GB)",
    READ: "Membaca",
    REVIEWER: "Pengulas",
    STICKY_NOTE: "Pesan tempel",
    TASK: "Tugas",
    USAGE: "Penggunaan",
    WRITE: "Menulis",
  },
  AUDIT: {
    ENTRY: "Pintu masuk",
    AUDIT_EMAIL: "Email audit",
    INCOMING: "Masuk",
    OUTGOING: "Keluar",
    AUDIT_TYPE: "Jenis audit",
    EMAIL_ADDRESS_DOMAIN: "Alamat email atau domain",
    NO_AUDIT_DOMAIN: "Tidak ada domain audit yang dibuat.Untuk mengaktifkan audit untuk domain, buka halaman domain.",
    NO_AUDIT_EMAIL: "Tidak ada email audit yang dibuat.Untuk menambahkan kotak surat jenis audit baru, buka halaman pengguna.",
  },
  LISTS: {
    ADD_LIST: "Tambahkan Daftar",
    NEW_LIST: "Daftar Baru",
    DEL_LIST: "Hapus Daftar",
    ADD_MEMBER: "Tambahkan Member",
    MEMBER: "Anggota",
    MEMBER_NAME: "Nama anggota",
    LIST_NAME: "Daftar Nama",
    LIST_TYPE: "Jenis Daftar",
    DISTRIBUTION_LIST: "Daftar Distribusi",
    SPAM_RETRAINER: "Spam Retrainer",
    NOT_SPAM_RETRAINER: "Bukan-Spam Retrainer",
    EXCHNAGE_ACCOUNT: "Akun Tingkat Lanjut",
    EMAIL_ADDRESS: "Alamat email",
    EMAIL_ADDRESS_DOMAIN: "Email atau Domain",
    INTERNAL_MEMBERS: "Anggota internal",
    EXTERNAL_MEMBERS: "Anggota eksternal",
  },
  SMARTLISTS: {
    ADD_LIST: "Add Smart List",
    NEW_LIST: "New Smart List",
    DEL_LIST: "Delete Smart List",
    UPDATE_LIST: "Update Smart List",
    SPECIFY_MEMBERS: "Specify Members",
    ALL_DOMAIN_USERS: "All domain users",
    DG_TYPE: "Dg type",
    LIST_PROTECTION: "List protection",
    ONLY_MEMBERS_POST: "Only members can post",
    PASSWORD_PROTECTED: "Password protected",
    NO_PROTECTION: "No protection",
    SEND_LABEL: "Send a copy of the message to the sender as well",
    MEMBER_MANAGEMENT: "Member Management",
    CAN_POST: "Can post",
    CAN_RECEIVE: "Receive list messages",
    MEMBERS_LIST: "Members List",
    MEMBERS: "Members",
    ALL_MEMBER_POST: "All members can post",
    MY_SUBJECT: "My Subject",
    PASSWORD_TXT:
      "When sending an email, enter this password at the beginning of the subject, in brackets.",
    EX: "EX",
  },
  FILTERING: {
    SETTINGS: {
      FILTER_SETTINGS: "Pengaturan penyaringan",
      ENABLE_AUTOWHITELIST: "Aktifkan Daftarwara Otomatis",
      FILTER_SENSITIVITY: "Tingkat sensitivitas penyaringan",
      KEEP_SPAM_FOR: "Tetap sebagai spam Untuk",
      SEND_SPAM_REPORT: "Kirim laporan Spam",
      SPAM_REPORT_CONTENT: "Konten Laporan Spam",
      SPAM_REPORT_FORMAT: "Format laporan Spam",
      LOGIN_LOCATION_LIMIT: "Batas Lokasi Login",
      NORMAL_SENSITIVITY: "Tingkat sensivitas normal",
      MOST_SPAM: "Biasanya spam akan melewati",
      MOST_MESSAGE: "Pesan yang disaring biasanya",
      ALL_SPAM_MESSAGES: "Semua pesan spam",
      LATEST_SPAM_MESSAGES: "Pesan spam terbaru",
      HTML_TEXT: "HTML dan TEXT",
      HTML_ONLY: "HTML saja",
      TEXT_ONLY: "TEXT saja",
      DONT_SEND: "jangan dikirim",
      EVERY_HOUR: "Setiap 1 jam",
      EVERY_HOURS: "Setiap {num} jam",
    },
    WHITELIST: {
      IGNORE_SPF: "Abaikan SPF",
      IGNORE_SPF_IP: "Abaikan SPF IP",
      SENDER_IP: "Alamat IP pengirim",
      IMPORT_DEC: "Unggah file dalam format CSV, satu alamat per baris.",
      ADD_WHITELIST: "Tambahkan Daftar Terpercaya",
      NEW_WHITELIST: "Daftar Terpercaya baru",
      DEL_WHITELIST: "Hapus Daftar Putih",
      IMPORT: "Impor",
      CHOOSE_PLACEHOLDER: "Pilih CSV atau letakkan di sini ...",
      DROP_PLACEHOLDER: "Letakkan file di sini ...",
      UPDATE_WHITELIST: "Perbarui Daftar Putih",
      INCLUDE_USERS_WHITELIST: "Sertakan daftar putih pengguna",
      APPLY_TO_HEADERS: "Apply to Headers",
    },
    BLACKLIST: {
      NEW_BLACKLIST: "Daftar Tidak Terpercaya baru",
      ADD_BLACKLIST: "Tambahkan Daftar Tidak Terpercaya",
      DEL_BLACKLIST: "Hapus Daftar Hitam",
      UPDATE_BLACKLIST: "Perbarui Daftar Hitam",
      INCLUDE_USERS_BLACKLIST: "Sertakan daftar hitam pengguna",
    },
    AUTO_WHITELIST: {
      ADDRESS_LIKE: "Alamat Suka",
      PICK_DATE: "Pilih Tanggal",
    },
    IPACCESS_LISTS: {
      PREFERENCE: "Pilihan",
      SELECT_IPTYPE: "Pilih Jenis IP",
    },
  },
  BRANDING: {
    FORM_DESC:
      "Pengaturan ini akan digunakan untuk semua user pada domain tersebut",
    COMPANY_COLOR: "Warna Perusahaan",
    COMPANY_NAME: "Nama Perusahaan",
    COMPANY_WEBLINK: "Link Website Perusahaan",
    SUPPORT_EMAIL: "Dukungan E-mail",
    COMPANY_LOGO: "Logo perusahaan",
    DEFAULT_LOGO: "Logo Default",
    DEFAULT_LOGO_DESC: "Logo Default untuk antarmuka Basic Group-Office",
    UPDATE_SETTINGS: "Perbarui pengaturan",
    PREVIEW: "Contoh Tampilan",
    PREVIEW_LOGO: "Pratinjau Logo",
  },
  SETTINGS: {
    CHANGE_PASSWORD: "Ubah Password Admin",
    CHNAGE_EMAIL: "Ganti e-mail",
    CHANGE_PASSWORD_DESC: "Anda dapat mengubah passowrd Anda",
    SYSTEM_TEXTS: "Teks Sistem",
    QUOTA: "Lansiran Kuota CC",
    NEW_PASSWORD: "Password baru",
    CONFIRM_PASSWORD: "Konfirmasi Password",
    CURRENT_EMAIL: "Email saat ini",
    NEW_EMAIL: "email baru",
    CHANGE_SYSTEXT: "Ubah Teks Sistem",
    TEXT_FOR: "Teks untuk",
    TEXT: "Teks",
    TIMEOUT: "Batas Waktu Panel Admin",
    MINUTES: "Menit",
    CHANGE_QUOTA_ALERT: "Ubah Peringatan Kuota",
    QUOTA_ALERT: "Lansiran Kuota",
    CHANGE_ADMIN_LOCALE: "Ubah Lokal Admin",
    CHANGE: "Ubah",
    FORGOT_PASSWORD_MAIL_TEXT: "Admin Mail Kata Sandi Reset Teks",
    FORGOT_PASSWORD_WEB_TEXT: "Kata Sandi Web Admin Reset Teks",
    QUOTA_WARNING_TEXT: "Teks Peringatan Kuota",
    QUOTA_WARNING_SUBJECT: "Subjek Peringatan Kuota",
    FORGOT_PASSWORD_MAIL_SUBJECT: "Subjek Reset Email Admin",
    PASSWORD_EXPIRY_WARNING_TEXT: "Teks peringatan kedaluwarsa kata sandi",
    PASSWORD_EXPIRY_WARNING_SUBJECT: "Subjek peringatan kadaluwarsa kata sandi",
    PASSWORD_EXPIRED_WARNING_TEXT: "Teks peringatan kedaluwarsa kata sandi",
    PASSWORD_EXPIRED_WARNING_SUBJECT: "Sandi subjek peringatan kedaluwarsa",
  },
  LOGS: {
    INCOMING_LOGS: {
      DELIVERED: "Dikirimkan",
      UNDELIVERED: "Yg tdk disampaikan",
      DELIVERED_TO: "Dikirim ke",
      ENVELOPE_FROM: "untuk",
      TO: "ke",
      SUBJECT: "Subyek",
      IP: "AKU P",
      SPAM_SCORE: "Skor Spam",
      DNSBL: "DNSBL",
      SPF: "SPF",
      DELIVERE: "Kirim",
      WHITELISTED: "Daftar Putih",
      WHITELIST_DELIVER: "Daftar Putih &amp; Kirim",
      HEADER_FROM: "Header From",
      REJECTED: "Ditolak",
      DETAILS: "Rincian",
      MORE: " & {num} LAGI",
    },
    OUTGOING_LOGS: {
      VIEW_MESSAGES: "Tampilkan pesan",
      SENT_FROM: "Dikirim dari",
      SENDER_IP: "Mengirim IP",
      SENT_TO: "Dikirim ke",
      FROM_DATE: "Dari Tanggal",
      TO_DATE: "Hingga Tanggal",
      DELIVERED: "Dikirimkan",
      TEMPORARILY_REJECTED: "Ditolak sementara",
      PERMANENTLY_REJECTED: "Ditolak permanen",
      ALL_MESSAGES: "Semua Pesan",
      DESC: "Antarmuka ini tidak menunjukkan pengiriman domain yang sama.",
      RECIPIENT_STATUS: "Status penerima pengiriman",
      MESSAGE_STATUS: "Status Pengiriman pesan",
    },
  },
  LAST_LOGINS: {
    START_DATE: "Mulai tanggal",
    END_DATE: "Tanggal Berakhir",
    NEGATE_CONDITION: "Kondisi Negasi",
    PASSWORD_USED: "Kata sandi yang digunakan",
    SERVICE_USED: "Layanan yang digunakan",
    RESET_SEARCH: "Atur Ulang Pencarian",
    LOCATION: "Lokasi",
    IMAP: "imap",
    POP3: "pop3",
    SMTP: "smtp",
    SIEVE: "sieve",
    LOGIN: "login",
    BLOCKED: "Diblokir",
  },
  SUB_ADMINS: {
    ACCOUNT_HISTORY: "Sejarah Akun",
    DGLISTS: "Daftar Pintar",
    NO_ACCESS: "Tidak ada akses",
    VIEW: "Melihat",
    ENABLE_DISABLE: "Aktifkan / Nonaktifkan",
    REMOVE: "Menghapus",
    IMPERSONATE: "Menghapus",
    ADD_UPDATE: "Tambahkan / Perbarui",
    MODIFY: "Memodifikasi",
    ADD_SUBADMIN: "Tambahkan Subadmin",
    DESC: "Izin tingkat yang lebih tinggi termasuk yang sebelumnya",
  },
  SECURITY: {
    ACTION:
      "Akun pengguna ini mungkin telah disusupi. Ubah sandi mereka segera dan perintahkan pengguna untuk menjalankan pemindaian anti-virus.",
    ADMIN_ACCESS_INFO: "Anda harus menambahkan setidaknya satu IP ke daftar", 
    ALLOW_BUTTON: "Izinkan akses Admin tidak terbatas",
    COUNTRIES: "Negara Login yang Berbeda",
    ENABLED_COUNTRY: "Diaktifkan untuk login dari lebih dari {num} negara",
    INFO: "Klik pada Pengguna untuk melihat login terakhirnya.",
    IP: "IP",
    IP_DESC: "Mengapa Anda mempercayai IP ini: nama lokasi, atau layanan.",
    IP_BLOCKED: "Alamat IP diblokir",
    IP_NOT_BLOCKED: "Alamat IP tidak diblokir",
    MARK_SAFE : "Tandai sebagai Aman",
    MONITOR_LOGINS: "Pantau login mencurigakan",
    NONE: "Tidak ada insiden keamanan yang terdeteksi dalam 24 jam terakhir.",
    RESTRICT_BUTTON: "Batasi akses Admin hanya ke IP dalam daftar",
    RESTRICT_STATUS_ACTIVE: "Akses admin dibatasi hanya untuk IP dalam daftar ini",
    RESTRICT_STATUS_FREE: "Akses admin tidak dibatasi",
    RESTRICT_WARNING: "Peringatan: pastikan IP Anda ada di Daftar Akses IP Admin. Jika tidak, Anda mungkin akan mengunci diri.",
    RESTRICT: "Membatasi",
    SECURITY_WARNING:
      "Insiden keamanan baru terdeteksi. Klik di sini untuk memeriksa halaman Keamanan.",
    SENT: "Email terkirim",
    TYPE_DESC: "Pilih apakah Anda ingin menandai IP atau seluruh jaringan sebagai aman",
    YOUR_IP: "Perhatian! IP Anda saat ini adalah {ip}, dan tidak ada dalam Daftar Akses IP Admin. Jika Anda melanjutkan, Anda akan mengunci diri!",
    VERIFY_IP: "Verifikasi apakah IP diblokir",
  },
  VALIDATION: {
    REQUIRED_FIELD: "Ini adalah kolom yang harus diisi.",
    DOMAIN_FIELD:
      "Bidang ini wajib diisi dan harus merupakan domain yang valid.",
    USERNAME_FIELD:
      "Bidang ini wajib diisi dan harus nama pengguna yang valid.",
    ALIAS_FIELD: "This is a required field and must be valid alias.",
    EMAIL_FIELD: "Ini adalah bidang wajib dan email.",
    EMAIL_DOMAIN_FIELD: "Ini adalah bidang email atau domain wajib.",
    QUOTA_FIELD: "Kuota yang tersedia adalah {num} GB.",
    QUOTA_ZERO: "Tidak ada kuota tersisa.",
    REQUIRED_MINLENGTH_FIELD:
      "Ini adalah bidang wajib diisi dan minimal harus {num} karakter.",
    IDENTICAL_FIELD: "Ini adalah bidang wajib dan harus identik.",
    IP_INVALID: "Alamat IP tidak valid",
    FILL_ALL_FIELDS: "Silakan isi semua bidang",
    MAX_VALUE: "Bidang ini wajib diisi dan harus kurang dari {num}",
    MAX_LETTERS: "Harus terdiri dari paling banyak {num} karakter.",
    MIN_LETTERS: "Harus sepanjang {num} karakter.",
    REGEX: "Harap cocokkan format yang diterima:: {rule}",
    NUMBERS: "nomor",
    LETTERS: "surat",
    LOWERCASE_LETTER: "Harus mengandung huruf kecil.",
    UPPERCASE_LETTER: "Harus mengandung huruf besar.",
    NUMBER_LETTER: "Harus mengandung angka.",
    SPECIAL_LETTER: "Harus mengandung karakter khusus.",
  },
  AUTH: {
    GENERAL: {
      OR: "Atau",
      SUBMIT_BUTTON: "Kirimkan",
      NO_ACCOUNT: "Belum punya akun?",
      SIGNUP_BUTTON: "Daftar",
      FORGOT_BUTTON: "Tidak ingat kata sandi",
      BACK_BUTTON: "Kembali",
      PRIVACY: "Pribadi",
      LEGAL: "Hukum",
      CONTACT: "Kontak",
      WELCOME_ADMIN: "Selamat datang di Panel Kontrol Admin!",
    },
    LOGIN: {
      TITLE: "Akun Masuk",
      BUTTON: "Masuk",
      DESC1:
        "Selamat datang di Panel Admin baru kami! Untuk mengakses versi lawas, silakan kunjungi: ",
      DESC2:
        "Jika Anda mengaktifkan 2FactorAuthentication, tambahkan 6 digit kode Anda di akhir kata sandi Anda",
      DESC3:
        "Dapatkan kode dari aplikasi autentikator Twofa di perangkat seluler Anda dan isi di bawah ini.",
      DESC4:
        "Tidak perlu lagi menambahkan kode Otentikasi Dua Faktor ke kata sandi.",
      TFA_INVALID: "Kode tidak valid atau kode telah kedaluwarsa",
      TFA: "Kode Anda:",
    },
    FORGOT: {
      TITLE: "Tidak ingat kata sandi?",
      SEND: "Kirim",
      DESC:
        "Jika nama pengguna Anda sama dengan alamat email Anda, harap masukkan dua kali.",
    },
    RESET_PASSWORD: {
      TITLE: "Menyetel Ulang Kata Sandi Anda...",
      DESC: "Klik tombol dan kami akan menghasilkan kata sandi baru.",
      GENERATE_NEW_PASSWORD: "Hasilkan Kata Sandi Baru",
      GO_LOGIN: "Pergi ke Halaman Login ",
    },
    REGISTER: {
      TITLE: "Daftar",
      DESC: "Masukkan detail Anda untuk membuat akun Anda",
      SUCCESS: "Akun Anda telah berhasil terdaftar.",
    },
    INPUT: {
      EMAIL: "Surel",
      PASSWORD: "Kata sandi",
      USERNAME: "Nama pengguna",
    },
    SIGN_OUT: "Keluar",
    RESET: "Setel ulang",
    VALIDATION: {
      EMAIL: "Email diperlukan dan alamat email yang valid.",
      PASSWORD: "Katakunci dibutuhkan.",
      USERNAME: "Nama pengguna harus diisi.",
    },
  },
  ZULIP:{
    IS_ADMIN: "Is Admin",
    UPDATE: "Update Zulip User",
    ERROR_STATUS: "Error on status update",
    ZULIP_ACCOUNTS: "Zulip Accounts",
  },
};
