// mutation types
export const APPEND_ISLOADING = "appendIsLoading";

// action types
export const SET_ISLOADING = "setIsLoading";

export default {
  state: {
    isLoading: false,
  },
  getters: {
    /**
     * Get state of loading
     * @param state
     * @returns {*}
     */
    getLoadingState(state) {
      return state.isLoading;
    },
  },
  actions: {
    /**
     * Set the loading state
     * @param state
     * @param payload
     */
    [SET_ISLOADING](state, payload) {
      state.commit(APPEND_ISLOADING, payload);
    },
  },
  mutations: {
    [APPEND_ISLOADING](state, payload) {
      state.isLoading = payload;
    },
  },
};
