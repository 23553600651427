import axios from "axios";
import JwtService from "@/common/jwt.service";
import { API_URL } from "@/common/config";
import store from "@/store";
import { LOGOUT } from "@/store/auth.module";
import { SET_ISLOADING } from "@/store/spin.module";
import route from "@/router";
import qs from "qs";

let ApiService = axios.create({
  baseURL: API_URL,
  timeout: 60000,
});

// request interceptor
ApiService.interceptors.request.use(
  (config) => {
    // console.log(getToken())
    store.dispatch(SET_ISLOADING, true);
    config.headers["x-token"] = JwtService.getToken();
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => {
    //console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
ApiService.interceptors.response.use(
  (response) => {
    // console.log(response)
    // const res = response.data
    // console.log(router.currentRoute)
    store.dispatch(SET_ISLOADING, false);
    if (
      response.data.returndata ==
      "Invalid login or your Session expired. Please log in again."
    ) {
      store
        .dispatch(LOGOUT, { token: localStorage.id_token, action: "logout" })
        .then(() => {
          route.push({ name: "login" });
        });
      return Promise.reject("error");
    } else {
      const data = response.data;
      return data;
    }
  },
  (error) => {
    //console.log(error);
    return Promise.reject(error);
  }
);

export default ApiService;
