// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Choisissez votre langue.",
  },
  MENU: {
    ACTIONS: "Actions",
    CREATE_POST: "Créer un nouveau message",
    PAGES: "Pages",
    FEATURES: "Caractéristiques",
    APPS: "Applications",
    HOME: "Accueil",
    SECURITY: "Sécurité",
    SECURITY_INCIDENTS: "Incidents de sécurité",
    TWOFA: "2FA",
    USERS: "Utilisateurs",
    MIGRATIONS: "Migrations",
    DOMAINS: "Domaines",
    COMPLIANCE: "Compliance",
    ALIASES: "Alias",
    LISTS: "Listes",
    FILTERING: "Filtration",
    WHITELIST: "Liste blanche",
    BLACKLIST: "Liste noire",
    AUTO_WHITELIST: "Liste blanche automatique",
    ADMIN_IPACCESS_LISTS: "Listes d'accès IP de l'Admin",
    IPACCESS_LISTS: "Listes d'accès IP",
    IPTRUSTED: "IP de confiance de l'Utilisateur",
    BRANDING: "Branding",
    SETTINGS: "Paramètres",
    LOGS: "Journaux",
    INCOMING_LOGS: "Journaux entrants",
    OUTGOING_LOGS: "Journaux sortants",
    LAST_LOGINS: "Dernières connexions",
    ALIAS_DOMAINS: "Alias de Domaines",
    SMARTLISTS: "Listes intelligentes",
    SUBADMINS: "Sub-Admins",
  },
  COMMON: {
    ACTION: "Action",
    ADMIN: "Admin",
    DATE: "Date",
    DAYS_ARRAY: {
      1: "Lundi",
      2: "Mardi",
      3: "Mercredi",
      4: "Jeudi",
      5: "Vendredi",
      6: "Samedi",
      7: "Dimanche",
    },
    RPERPAGE: "Lignes par page",
    ENABLED: "Activée",
    DISABLED: "Désactivé",
    SAVE: "Sauver",
    STEP: "Étape",
    CANCEL: "Annuler",
    CLOSE: "Fermer",
    RESET: "Effacer",
    NEXT: "Suivant",
    SEL_DOMAIN: "Sélectionnez un domaine",
    SEL_USER: "Select User",
    SEARCH: "Chercher",
    USERNAME: "Nom d'utilisateur",
    USER_NAME: "Nom d'utilisateur",
    DOMAIN: "Domaine",
    TYPE: "Type",
    RANGE: "Masque réseau",
    PASSWORD: "Mot de passe",
    QUOTA: "Espace (GB)",
    NEW: "Nouveau",
    COPY: "Copie",
    COPY_CLIPBOARD: "Copier dans le presse-papier",
    ADD: "Ajouter",
    LANGUAGE: "Langue",
    LOCALIZATION: "Localisation",
    DATE_FORMAT: "Format de date",
    UPDATE: "Mise à jour",
    DEL_WARNING:
      "Toutes les données seront perdues! Êtes-vous sûr de vouloir effacer ?",
    DEL_ITEM_WARNING:
      "All data will be lost! Are you sure you want to remove {item}?",
    DEL_CONFIRM: "Confirmation de supprimation",
    HOME_COUNTRY: "Pays de résidence",
    TRAVEL_MODE: "Mode Voyage",
    TIMEZONE: "Fuseau horaire",
    ACTIVE: "Actif",
    INACTIVE: "Inactif",
    YES: "Oui",
    NO: "Non",
    NO_DATA: "Pas de données disponibles",
    ADDRESS: "Adresse",
    CHECK_SPAM: "Vérifier le spam",
    CHECK_VIRUS: "Vérifier le virus",
    BROWSE: "Feuilleter",
    DESCRIPTION: "La description",
    DELETE: "Supprimer",
    EDIT: "Éditer",
    NOT_ALLOWED: "Interdit",
    ALLOWED: "Permis",
    DAY: "journée",
    DAYS: "journées",
    DESTINATION: "Destination",
    VALUE: "Valeur",
    FOOTER: "Bas de page",
    MORE_DETAILS: "More details",
    OPTIONAL: "Optional",
    OTPASSWORD: "OTPassword",
    OTHER_ADMIN: "Autre administrateur",
    SEARCH_OPTIONS: "Options de recherche",
    SEARCH_RESULTS: "Résultats de recherche",
    SOURCE: "Source",
    STATUS: "Statut",
    ADMIN_PANEL: "Panneau d'administration",
    SUCCESS: "Succès",
    ERROR: "Erreur",
    SUCCESS_ALERT: "L'opération a réussi!",
    ERROR_ALERT: "L'opération a été déposée!",
    WARNING: "Attention",
    OK: "D'accord",
    HI: "Salut",
    TAB_LOGO: "Panneau d'Administration",
    AUTO_LOGOUT: "Deconnexion Automatique",
    ALL_DOMAINS: "Tous les Domaines",
    FULL_NAME: "Nom et prénom",
    WAIT_MESSAGE: "S'il vous plaît permettre 5 minutes pour propager les modifications",
    REGULAR: "Régulière",
    REFRESH: "Actualiser"
  },
  AUTO_CLOSE: {
    TITLE: "Automatic Logout",
    DESC:
      "Nous n'avons détecté aucune activité pendant près de 5 minutes. Rester connecté?",
    TIME_REMAIN: "{sec} secondes pour vous déconnecter.",
  },
  HOME: {
    ADMIN_ACCOUNT: "Compte administrateur",
    STATISTICS: "Statistiques",
    REMOTE_IP: "Adresse IP",
    BASIC_ACCOUNTS: "Comptes Basic",
    ENHANCED_ACCOUNTS: "Comptes Enhanced",
    DESC1:
      "Pour une sécurité accrue, envisagez d'activer l'authentification à a 2 facteurs pour ce compte",
    DESC2:
      "Installez simplement une application OTP comme <a href='https://www.authy.com/download'>Authy</a> et cliquez sur 2FA dans le menu.",
    DESC3: "Authentification à 2 facteurs non activée!",
  },
  TWOFA: {
    AUTHY: "Authy",
    TFAUTH: "Authentification à deux facteurs",
    SEL_STATUS: "Sélectionnez le statut",
    DEC1:
      "Veuillez vous assurer que vous avez déjà installé une application OTP, telle que",
    DEC2:
      "À l'étape 2, vous devrez scanner le code QR à l'aide de l'application OTP.",
    DEC3: "Lorsque vous êtes prêt, appuyez sur le bouton «SUIVANT» ci-dessous.",
    SCAN_DEC1: "Scannez ce code QR à l'aide de votre application OTP.",
    SCAN_DEC2: "Une fois le compte ajouté, vous recevrez un code à 6 chiffres",
    SCAN_DEC3: "Entrez le code dans le champ ci-dessous.",
    SCAN_DEC4:
      "Appuyez sur le bouton «ENREGISTRER» ci-dessous avant l'expiration de votre code.",
    CHALLENGE: "Défi",
    DISABLE_TITLE: "Désactiver l'authentification à deux facteurs",
    DDEC1:
      "Pour désactiver l'authentification à 2 facteurs, insérez le code de votre application OTP (Authy).",
    DDEC2:
      "Appuyez sur le bouton «ENREGISTRER» ci-dessous avant l'expiration de votre code. Si cela se produit, insérez à nouveau votre nouveau code généré à partir de l'application OTP (Authy).",
  },
  USERS: {
    QUSAGE: "Quota et utilisation",
    EXPORT_RESULTS: "Exporter les résultats",
    NEW_USER: "Nouvel utilisateur",
    DEL_USER: "Supprimer l'utilisateur",
    ADD_USER: "Ajouter un utilisateur",
    UPDATE_USER: "Mettre à jour l'utilisateur",
    PASS_DEC: "Veuillez vous assurer d'enregistrer ce mot de passe",
    ACCOUNT_TYPE: "Type de compte",
    ACCOUNT_TYPE_OEX: "Type de compte OEX",
    NAME_OF_USER: "Nom de l'utilisateur",
    FACTOR_AUTH: "2Factor Auth",
    ONETIME_TITLE: "Mot de passe unique créé!",
    ONETIME_SUCCESS: "Ce mot de passe n'est valable que 5 minutes!",
    BASIC: "De base",
    ENHANCED: "Renforcée",
    NEVER: "Jamais",
    LAST_LOGIN: "Dernière connexion",
    BASIC_ACCOUNT: "Compte de base",
    ENHANCED_ACCOUNT: "Compte amélioré",
    AUDIT_ACCOUNT: "Audit Account",
    DISABLE_SMTP_ONLY: "désactiver SMTP uniquement",
    USER_QUOTA: "{num1} Go libre de {num2} Go",
    USER_CONTROL_PANEL: "Panneau de contrôle utilisateur",
    EXCHANGE: "Connecteur Exchange",
    EXCHANGE_ACCOUNTS: "Connecteur Exchange",
    OFFICE365: "Connecteur Externe",
    OFFICE365_ACCOUNTS: "Connecteur Externe",
    MORE_OPTIONS: "Plus d'options",
    LESS_OPTIONS: "Moins d'options",
    ONLY_LOCAL_SMTP: "Only Local SMTP",
    PERM: {
      ACCESS_HOURS: "Accéder au e-mail uniquement entre les heures",
      APP_PASS: "Mots de passe d'application",
      CAN_ACCESS: "Peut accéder:",
      CHANGE_PASS: "Changer le mot de passe",
      DELIV_RULES: "Règles de livraison",
      DISABLED_INFO: "Les utilisateurs en rouge sont désactivés ou ont des heures de connexion restreintes",
      EXTENSIONS: "Extensions",
      FOLDERS_CLEANUP: "Nettoyage des dossiers",
      FORWARDS: "Forwards",
      HOURS_BOTH: "Veuillez indiquer l'heure de début et l'heure de fin",
      HOURS_SIZE: "L'heure de début doit être inférieure à l'heure de fin",
      IMAP: "IMAP",
      LAST_LOGINS: "Dernières connexions",
      LOCAL_DELIV: "Livraison locale",
      PERMISSIONS: "Autorisations",
      POP: "POP3",
      SERVICE_PERM: "Autorisations de service",
      SMTP: "SMTP",
      SPAM_QUARANTINE: "Quarantaine de Spam",
      UNRESTRICTED: "Sans restriction",
      UPDATE_HOURS: "Mettre à jour les heures d'accès",
      UPDATE_PERM: "Mettre à jour",
      VACATION_MSG: "Message de vacances",
      SEND_AS: "Envoyer en tant que",
      CAN_SEND_AS: "Gérer les utilisateurs qui peuvent envoyer en tant que ",
    },
  },
  MIGRATE: {
    COMPLETE: "Complete",
    DEFAULT: "Default",
    DETAILS: "Migration Details",
    END_TIME: "End Time",
    ETA: "ETA",
    EXIT_MESSAGE: "Exit Message",
    EXIT_STATUS: "Exit Status",
    FOLDER: "Destination Folder",
    LOCAL_MIGRATION: "Local Migration",
    MIGRATE: "Migrate",
    MIGRATE_TO_FOLDER: "Migrate to Folder",
    NEW_MIGRATION: "New Migration",
    PROGRESS: "Progress",
    REFRESHING: "Refreshing every 10s",
    SCHEDULE: "Schedule",
    SECONDS_LEFT: "seconds left",
    STAGE: "Stage",
    START_TIME: "Start Time",
  },
  DOMAINS: {
    NEW_DOMAIN: "Nouveau domaine",
    DEL_DOMAIN: "Supprimer un domaine",
    ADD_DOMAIN: "Ajouter un domaine",
    DOMAIN_NAME: "Nom de domaine:",
    DOMAIN_TIMEZONE: "Fuseau horaire du domaine:",
    CATCHALL: "Catchall",
    WEBMAIL: "Webmail",
    EXC_ENABLED: "Activé pour Exchange",
    LOCAL_DELIVERY: "Local Delivery",
    AUDIT_ENABLED: "Audit Enabled",
    EDIT_FOOTER: "Modifier le pied de page des messages",
    FOOTER_DESC:
      "Le message que vous entrez ci-dessous sera ajouté à tous les messages envoyés par tous les utilisateurs",
    OTHER: "Autre",
    ENHANCED_GROUP_OFFICE: "Enhanced (Group-Office)",
    GROUP_OFFICE: "GroupOffice",
    NO_CATCHALL: "Pas de Catchall",
    ACCEPT_DELETE: "Accepter et supprimer",
    NEW_ALIAS_DOMAIN: "Nouveau domaine d'alias",
    ADD_ALIAS_DOMAIN: "Ajouter un domaine d'alias",
    DEL_ALIAS_DOMAIN: "Supprimer le domaine d'alias",
    DKIM_SETTINGS: "Configuration DKIM",
    ENABLED: "Activee",
    HOST: "Hote",
    KEY: "Cle",
    DOMAIN_TYPE: "Type de domaine",
    VERIFYING: "Vérification...",
    VERIFY: "Vérifier",
    HOSTNAME: "Hostname",
    PLEASE_VERIFY: "Veuillez vérifier la propriété du domaine en ajoutant cet enregistrement à DNS:",
    OEX: "OEX",
    VERIFY_AGAIN: "Vérifiez à nouveau",
    HEALTH: "Santé",
    DOMAIN_HEALTH: "Santé du domaine:",
    CHECK_HEALTH_INFO: "Votre domaine est actuellement en file d'attente pour vérification.Veuillez prévoir quelques minutes pour que le processus se termine.",
    MX_RECORDS: "Registres MX",
    CAA_RECORD: "Registre CAA",
    WEBMAIL_RECORD: "Registre Webmail",
    WEBMAIL_SSL: "Webmail SSL",
    AUTODISCOVER_RECORD: "Registre Autodiscover",
    AUTODISCOVER_SSL: "Autodiscover SSL",
    SPF_RECORD: "Registre SPF",
    OK: "OK",
    FAIL: "Échouer",
    INSTALLED: "Installée",
    NOT_INSTALLED: "Pas installé",
    FOUND: "Trouvée",
    NOT_FOUND: "Pas trouvé",
    EXPL_MX: "Si ce n'est pas correctement défini, les couriels ne seront pas envoyés à votre domaine",
    EXPL_CAA: "Si ce n'est pas correctement défini, nous ne pouvons pas générer des certificats SSL",
    EXPL_WEBMAIL: "Si ce n'est pas correctement défini, l'accès Webmail pour votre domaine sera restreint",
    EXPL_WEBMAIL_SSL: "Si ce n'est pas correctement défini, les connexions Webmail peuvent être peu sûres",
    EXPL_AUTODISCOVER: "Si ce n'est pas correctement défini, les clients de messagerie ne se configureront pas automatiquement, ce qui entraînera des problèmes de configuration",
    EXPL_AUTODISCOVER_SSL: "Si ce n'est pas correctement défini, les connexions de découverte automatique ne seront pas en sécurité, risquant l'exposition aux données",
    EXPL_SPF: "Si ce n'est pas correctement défini, vos e-mails pourraient être marqués en spam ou rejeté",
  },
  ALIASES: {
    NEW_ALIAS: "Nouvel alias",
    ADD_ALIAS: "Ajouter un alias",
    DEL_ALIAS: "Supprimer l'alias",
    UPDATE_ALIAS: "Mise à jour d'alias",
    ALIAS: "Alias",
    FORWARDS_TO: "Renvoye à",
    FORWARDS_DESTINATION: "Destination",
    INTERNAL: "Interne",
    EXTERNAL: "Externe",
  },
  PUBLIC_FOLDERS: {
    ADD_FOLDER: "Ajouter un dossier",
    ADD_OWNER: "Ajouter un propriétaire",
    ALL: "Tous",
    AUTHOR: "Auteur",
    APPOINTMENT: "Rendez-vous",
    CONTACT: "Contact",
    CONTAINER: "Récipient",
    CONTRIBUTOR: "Contributeur",
    CREATE_ITEMS: "Créer des articles",
    CREATE_SUBFOLDERS: "Créer des sous-dossiers",
    DELETE_ITEMS: "Supprimer des éléments",
    EDIT_ALL: "Modifier tout",
    EDIT_OWN: "Modifier soi-même",
    EDITOR: "Rédacteur en chef",
    FOLDER_CONTACT: "Contact du dossier",
    FOLDER_DETAILS: "Détails du dossier",
    FOLDER_NAME: "Nom du dossier",
    FOLDER_OWNER: "Propriétaire du dossier",
    FOLDER_VISIBLE: "Dossier visible",
    FULL_DETAILS: "Détails complets",
    MAIL_AND_POST_ITEMS: "Article de courrier et de poste",
    NO_OWNERS: "Aucun propriétaire",
    NONE: "Aucun",
    NONEDITING_AUTHOR: "Auteur non rédacteur",
    OTHER: "Autre",
    OWN: "Propre",
    OWNER: "Propriétaire",
    OWNERS: "Propriétaires",
    PROFILE: "Profil",
    PUBLIC_FOLDER: "Dossier public",
    PUBLISHING_AUTHOR: "Auteur de la publication",
    PUBLISHING_EDITOR: "Rédacteur en chef de la publication",
    QUOTA: "Quota (Go)",
    READ: "Lire",
    REVIEWER: "Critique",
    STICKY_NOTE: "Note autocollante",
    TASK: "Tâche",
    USAGE: "Utilisation",
    WRITE: "Write",
  },
  AUDIT: {
    ENTRY: "Entry",
    AUDIT_EMAIL: "Audit email",
    INCOMING: "Incoming",
    OUTGOING: "Outgoing",
    AUDIT_TYPE: "Audit type",
    EMAIL_ADDRESS_DOMAIN: "Email Address or Domain",
    NO_AUDIT_DOMAIN: "No audit domain created. To enable audit for a domain, go to the Domain page.",
    NO_AUDIT_EMAIL: "No audit email created. To add a new Audit type mailbox, go to the User page.",
  },
  LISTS: {
    ADD_LIST: "Ajouter la liste",
    NEW_LIST: "Nouvelle liste",
    DEL_LIST: "Supprimer la liste",
    ADD_MEMBER: "Ajouter un membre",
    MEMBER: "Membre",
    MEMBER_NAME: "Nom du membre",
    LIST_NAME: "Nom de la liste",
    LIST_TYPE: "Type de liste",
    DISTRIBUTION_LIST: "Liste de distribution",
    SPAM_RETRAINER: "Spam Retrainer",
    NOT_SPAM_RETRAINER: "Not Spam Retrainer",
    EXCHNAGE_ACCOUNT: "Compte Exchange",
    EMAIL_ADDRESS: "Adresse électronique",
    EMAIL_ADDRESS_DOMAIN: "E-mail ou domaine",
    INTERNAL_MEMBERS: "Membre interne",
    EXTERNAL_MEMBERS: "Membre externe",
  },
  SMARTLISTS: {
    ADD_LIST: "Add Smart List",
    NEW_LIST: "New Smart List",
    DEL_LIST: "Delete Smart List",
    UPDATE_LIST: "Update Smart List",
    SPECIFY_MEMBERS: "Specify Members",
    ALL_DOMAIN_USERS: "All domain users",
    DG_TYPE: "Dg type",
    LIST_PROTECTION: "List protection",
    ONLY_MEMBERS_POST: "Only members can post",
    PASSWORD_PROTECTED: "Password protected",
    NO_PROTECTION: "No protection",
    SEND_LABEL: "Send a copy of the message to the sender as well",
    MEMBER_MANAGEMENT: "Member Management",
    CAN_POST: "Can post",
    CAN_RECEIVE: "Receive list messages",
    MEMBERS_LIST: "Members List",
    MEMBERS: "Members",
    ALL_MEMBER_POST: "All members can post",
    MY_SUBJECT: "My Subject",
    PASSWORD_TXT:
      "When sending an email, enter this password at the beginning of the subject, in brackets.",
    EX: "EX",
  },
  FILTERING: {
    SETTINGS: {
      FILTER_SETTINGS: "Paramètres du filtre",
      ENABLE_AUTOWHITELIST: "Activer la liste blanche automatique",
      FILTER_SENSITIVITY: "Sensibilité du filtre",
      KEEP_SPAM_FOR: "Conserver le spam pendant",
      SEND_SPAM_REPORT: "Envoyer un rapport de spam",
      SPAM_REPORT_CONTENT: "Contenu du rapport de spam",
      SPAM_REPORT_FORMAT: "Format du rapport de spam",
      LOGIN_LOCATION_LIMIT: "Limite de la source des connexions",
      NORMAL_SENSITIVITY: "Sensibilité normale",
      MOST_SPAM: "La plupart du spam passe",
      MOST_MESSAGE: "La plupart des messages sont filtrés",
      ALL_SPAM_MESSAGES: "Tous les spams",
      LATEST_SPAM_MESSAGES: "Derniers messages de spam",
      HTML_TEXT: "HTML et TEXTE",
      HTML_ONLY: "HTML uniquement",
      TEXT_ONLY: "Texte seulement",
      DONT_SEND: "N'envoyez pas",
      EVERY_HOUR: "Toutes les 1 heure",
      EVERY_HOURS: "Toutes les {num} heures",
    },
    WHITELIST: {
      IGNORE_SPF: "Ignorer SPF",
      IGNORE_SPF_IP: "Ignorer SPF pour IP",
      SENDER_IP: "Adresse IP de l'expéditeur",
      IMPORT_DEC:
        "Veuillez télécharger un fichier au format CSV, une adresse par ligne",
      ADD_WHITELIST: "Ajouter une liste blanche",
      NEW_WHITELIST: "Nouvelle liste blanche",
      DEL_WHITELIST: "Supprimer la liste blanche",
      IMPORT: "Importation",
      CHOOSE_PLACEHOLDER: "Choisissez un CSV ou déposez-le ici ...",
      DROP_PLACEHOLDER: "Déposer le fichier ici ...",
      UPDATE_WHITELIST: "Mettre à jour la liste blanche",
      INCLUDE_USERS_WHITELIST: "Inclure la liste blanche des utilisateurs",
      APPLY_TO_HEADERS: "Apply to Headers",
    },
    BLACKLIST: {
      NEW_BLACKLIST: "Nouvelle liste noire",
      ADD_BLACKLIST: "Ajouter une liste noire",
      DEL_BLACKLIST: "Supprimer la liste noire",
      UPDATE_BLACKLIST: "Mettre à jour la liste noire",
      INCLUDE_USERS_BLACKLIST: "Include la liste noire des utilisateurs",
    },
    AUTO_WHITELIST: {
      ADDRESS_LIKE: "Adresse comme",
      PICK_DATE: "Choisis une date",
    },
    IPACCESS_LISTS: {
      PREFERENCE: "Préférence",
      SELECT_IPTYPE: "Sélectionnez le type IP",
    },
  },
  BRANDING: {
    FORM_DESC:
      "Ces paramètres s'appliqueront à tous les utilisateurs du domaine",
    COMPANY_COLOR: "Couleur de l'entreprise",
    COMPANY_NAME: "Nom de la compagnie",
    COMPANY_WEBLINK: "Page web de l'entreprise",
    SUPPORT_EMAIL: "Support e-mail",
    COMPANY_LOGO: "Logo de l'entreprise",
    DEFAULT_LOGO: "Logo par défaut",
    DEFAULT_LOGO_DESC: "Logo par défaut pour l'interface Basic Group-Office",
    UPDATE_SETTINGS: "Mettre à jour les paramètres",
    PREVIEW: "Aperçu",
    PREVIEW_LOGO: "Aperçu du logo",
  },
  SETTINGS: {
    CHANGE_PASSWORD: "Changer le mot de passe",
    CHNAGE_EMAIL: "Changer l'e-mail",
    CHANGE_PASSWORD_DESC: "Vous pouvez changer votre mot de passe",
    SYSTEM_TEXTS: "Textes système",
    QUOTA: "Alerte de quota CC",
    NEW_PASSWORD: "nouveau mot de passe",
    CONFIRM_PASSWORD: "Confirmez le mot de passe",
    CURRENT_EMAIL: "Email actuel",
    NEW_EMAIL: "nouveau courriel",
    CHANGE_SYSTEXT: "Modifier les textes du système",
    TEXT_FOR: "Texte pour",
    TEXT: "Texte",
    TIMEOUT: "Délai d'expiration du Admin Panel",
    MINUTES: "Minutes",
    CHANGE_QUOTA_ALERT: "Alerte de changement de quota",
    QUOTA_ALERT: "Alerte de quota",
    CHANGE_ADMIN_LOCALE: "Modifier les paramètres régionaux d'administrateur",
    CHANGE: "Changement",
    FORGOT_PASSWORD_MAIL_TEXT: "Admin Mail Password Reset Text",
    FORGOT_PASSWORD_WEB_TEXT: "Admin Web Password Reset Text",
    QUOTA_WARNING_TEXT: "Texte d'avertissement de quota",
    QUOTA_WARNING_SUBJECT: "Objet d'avertissement de quota",
    FORGOT_PASSWORD_MAIL_SUBJECT: "Admin Reset Reset Subject",
    PASSWORD_EXPIRY_WARNING_TEXT:
      "Texte d'avertissement d'expiration du mot de passe",
    PASSWORD_EXPIRY_WARNING_SUBJECT:
      "Objet d'avertissement d'expiration du mot de passe",
    PASSWORD_EXPIRED_WARNING_TEXT:
      "Texte d'avertissement de mot de passe expiré",
    PASSWORD_EXPIRED_WARNING_SUBJECT:
      "Objet d'avertissement de mot de passe expiré",
  },
  LOGS: {
    INCOMING_LOGS: {
      DELIVERED: "Livré",
      UNDELIVERED: "non remis",
      DELIVERED_TO: "Livré à",
      ENVELOPE_FROM: "Enveloppe de",
      TO: "À",
      SUBJECT: "Sujet",
      IP: "IP",
      SPAM_SCORE: "Score de spam",
      DNSBL: "DNSBL",
      SPF: "SPF",
      DELIVERE: "Livrer",
      WHITELISTED: "Liste blanche",
      WHITELIST_DELIVER: "Liste blanche et livraison",
      HEADER_FROM: "En-tête De",
      REJECTED: "Rejeté",
      DETAILS: "Détails",
      MORE: " & {num} AUTRES",
    },
    OUTGOING_LOGS: {
      VIEW_MESSAGES: "Afficher les messages",
      SENT_FROM: "Envoyé de",
      SENDER_IP: "Envoi IP",
      SENT_TO: "Envoyé à",
      FROM_DATE: "Partir de la date",
      TO_DATE: "À ce jour",
      DELIVERED: "Livré",
      TEMPORARILY_REJECTED: "Rejeté temporairement",
      PERMANENTLY_REJECTED: "Rejeté définitivement",
      ALL_MESSAGES: "Tous les messages",
      DESC: "Cette interface n'affiche pas les livraisons du même domaine",
      RECIPIENT_STATUS: "Statut de livraison du destinataire",
      MESSAGE_STATUS: "Statut de remise des messages",
    },
  },
  LAST_LOGINS: {
    START_DATE: "Date de début",
    END_DATE: "Date de fin",
    NEGATE_CONDITION: "Annuler la condition",
    PASSWORD_USED: "Mot de passe utilisé",
    SERVICE_USED: "Service utilisé",
    RESET_SEARCH: "Réinitialiser la recherche",
    LOCATION: "Emplacement",
    IMAP: "imap",
    POP3: "pop3",
    SMTP: "smtp",
    SIEVE: "sieve",
    LOGIN: "login",
    BLOCKED: "Bloquée",
  },
  SUB_ADMINS: {
    ACCOUNT_HISTORY: "Historique compte",
    DGLISTS: "Listes intelligentes",
    NO_ACCESS: "Aucun acces",
    VIEW: "Visionner",
    ENABLE_DISABLE: "Activer / Desactiver",
    REMOVE: "Effacer",
    IMPERSONATE: "Impersonnifier",
    ADD_UPDATE: "Ajouter / Mise a jour",
    MODIFY: "Modifier",
    ADD_SUBADMIN: "Ajouter Sub-Admin",
    DESC:
      "Les permissions de niveau supérieur incluent celles qui les précèdent",
  },
  SECURITY: {
    ACTION:
      "Ces utilisateurs peuvent avoir leur compte compromis. Changer son mot de passe immédiatement et demander à l'utilisateur d'exécuter un logiciel antivirus.",
    ADMIN_ACCESS_INFO: "Vous devez ajouter au moins une adresse IP à la liste", 
    ALLOW_BUTTON: "Accès Admin sans limite",
    COUNTRIES: "Pays distincts",
    ENABLED_COUNTRY: "Activé pour les connexions provenant de plus de {num} pays",
    INFO: "Cliquez sur l'utilisateur pour voir ses dernières connexions.",
    IP: "IP",
    IP_DESC: "Pourquoi faites-vous confiance à cette adresse IP: nom de l'emplacement ou du service.",
    IP_BLOCKED: "L'adresse IP est bloquée",
    IP_NOT_BLOCKED: "L'adresse IP n'est pas bloquée",
    MARK_SAFE : "Marquer comme sûr",
    MONITOR_LOGINS: "Surveiller les connexions suspectes",
    NONE:
      "Aucun incident de sécurité n'a été détecté au cours des dernières 24 heures.",
    RESTRICT_BUTTON: "Limiter l'accès administrateur uniquement aux adresses IP dans la liste",
    RESTRICT_STATUS_ACTIVE: "L'accès administrateur est limité seulement aux adresses IP de cette liste",
    RESTRICT_STATUS_FREE: "L'accès administrateur est illimité",
    RESTRICT_WARNING: "Avertissement: assurez-vous que votre adresse IP est dans la liste d'accès IP de l'Admin. Sinon, vous risquez de vous enfermer.",
    RESTRICT: "Limiter",
    SECURITY_WARNING:
      "Nouveaux incidents de sécurité détectés. Cliquez ici pour consulter la page Sécurité.",
    SENT: "E-mails envoyés",
    TYPE_DESC: "Choisissez si vous souhaitez marquer comme sûr l'IP ou l'ensemble du réseau",
    YOUR_IP: "Attention! Votre adresse IP actuelle est {ip} et ne figure pas dans la liste d'accès IP de l'administrateur. Si vous continuez, vous vous enfermerez!",
    VERIFY_IP: "Vérifiez si l'adresse IP est bloquée",
  },
  VALIDATION: {
    REQUIRED_FIELD: "Ceci est un champ obligatoire.",
    DOMAIN_FIELD:
      "Il s'agit d'un champ obligatoire et doit être un domaine valide.",
    USERNAME_FIELD:
      "Ce champ est obligatoire et doit contenir un nom d'utilisateur valide.",
    ALIAS_FIELD: "This is a required field and must be valid alias.",
    EMAIL_FIELD: "Ceci est un champ obligatoire et doit contenir un email.",
    EMAIL_DOMAIN_FIELD: "Ceci est un champ d'adresse e-mail ou de domaine obligatoire.",
    QUOTA_FIELD: "Le quota disponible est de {num} Go.",
    QUOTA_ZERO: "Plus de quota.",
    REQUIRED_MINLENGTH_FIELD:
      "Ce champ est obligatoire et doit comporter au moins {num} caractères.",
    IDENTICAL_FIELD:
      "Il s'agit d'un champ obligatoire et il doit être identique.",
    IP_INVALID: "Adresse IP invalide",
    FILL_ALL_FIELDS: "Veuillez remplir tous les champs.",
    MAX_VALUE: "Ce champ est obligatoire et doit être inférieur à {num}",
    MAX_LETTERS: "Doit contenir au plus {num} caractères.",
    MIN_LETTERS: "Doit contenir au moins {num} characteres.",
    REGEX: "Veuillez faire correspondre le format accepté: {rule}",
    NUMBERS: "nombres",
    LETTERS: "lettres",
    LOWERCASE_LETTER: "Doit contenir une lettre minuscule.",
    UPPERCASE_LETTER: "Doit contenir une lettre majuscule.",
    NUMBER_LETTER: "Doit contenir un nombre.",
    SPECIAL_LETTER: "Doit contenir un charactere special",
  },
  AUTH: {
    GENERAL: {
      OR: "Ou",
      SUBMIT_BUTTON: "Soumettre",
      NO_ACCOUNT: "Vous n'avez pas de compte?",
      SIGNUP_BUTTON: "S'inscrire",
      FORGOT_BUTTON: "Mot de passe oublié",
      BACK_BUTTON: "Arrière",
      PRIVACY: "Intimité",
      LEGAL: "Légal",
      CONTACT: "Contact",
      WELCOME_ADMIN: "Bienvenue au nouveau Panneau d'Administration!",
    },
    LOGIN: {
      TITLE: "Connexion au compte",
      BUTTON: "Se connecter",
      DESC1:
        "Bienvenue dans notre nouveau panneau d'administration! Pour accéder à la version héritée, veuillez visiter:",
      DESC2:
        "Si vous avez activé 2 Factor Authentication, ajoutez votre code à 6 chiffres à la fin de votre mot de passe",
      DESC3:
        "Obtenez le code de l'application d'authentification Twofa sur votre appareil mobile et remplissez-le ci-dessous.",
      DESC4:
        "Il n'est plus nécessaire d'ajouter le code d'authentification à deux facteurs au mot de passe.",
      TFA_INVALID: "Code invalide ou le code a expiré",
      TFA: "Votre code:",
    },
    FORGOT: {
      TITLE: "Mot de passe oublié?",
      SEND: "Envoyer",
      DESC:
        "Si votre nom d'utilisateur est le même que votre adresse e-mail, veuillez le saisir deux fois",
    },
    RESET_PASSWORD: {
      TITLE: "Réinitialisation de votre mot de passe ...",
      DESC:
        "Cliquez sur le bouton et nous allons générer un nouveau mot de passe.",
      GENERATE_NEW_PASSWORD: "Générer un nouveau mot de passe",
      GO_LOGIN: "Accéder à la page d'accueuil",
    },
    REGISTER: {
      TITLE: "S'inscrire",
      DESC: "Entrez vos coordonnées pour créer votre compte",
      SUCCESS: "Votre compte a bien été enregistré.",
    },
    INPUT: {
      EMAIL: "Email",
      PASSWORD: "Mot de passe",
      USERNAME: "Nom d'utilisateur",
    },
    SIGN_OUT: "Déconnexion",
    RESET: "Réinitialiser",
    VALIDATION: {
      EMAIL: "Un e-mail est requis et une adresse e-mail valide.",
      PASSWORD: "Mot de passe requis.",
      USERNAME: "Nom d'utilisateur est nécessaire.",
    },
  },
  ZULIP:{
    IS_ADMIN: "Is Admin",
    UPDATE: "Update Zulip User",
    ERROR_STATUS: "Error on status update",
    ZULIP_ACCOUNTS: "Zulip Accounts",
  },
};
