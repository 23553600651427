import Vue from "vue";
Vue.mixin({
  methods: {
    hasPermission(permission, level) {
      if (permission == "all") return true;

      permission = localStorage.getItem("perm_" + permission);
      if (permission && permission >= level) return true;

      return false;
    },
  },
});
