// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Wähle deine Sprache.",
  },
  MENU: {
    ACTIONS: "Aktionen",
    CREATE_POST: "Neuen Beitrag erstellen",
    PAGES: "Seiten",
    FEATURES: "Eigenschaften",
    APPS: "Apps",
    HOME: "Zuhause",
    SECURITY: "Sicherheit",
    SECURITY_INCIDENTS: "Sicherheitsvorfälle",
    TWOFA: "2FA",
    USERS: "Benutzer",
    MIGRATIONS: "Migrations",
    DOMAINS: "Domänen",
    COMPLIANCE: "Compliance",
    ALIASES: "Aliase",
    LISTS: "Listen",
    FILTERING: "Filtern",
    WHITELIST: "Whitelist",
    BLACKLIST: "Schwarze Liste",
    AUTO_WHITELIST: "Auto Whitelist",
    ADMIN_IPACCESS_LISTS: "Admin IP-Zugriffslisten",
    IPACCESS_LISTS: "IP-Zugriffslisten",
    IPTRUSTED: "Benutzervertrauenswürdige Anmelde-IPs",
    BRANDING: "Branding",
    SETTINGS: "die Einstellungen",
    LOGS: "Protokolle",
    INCOMING_LOGS: "Eingehende Protokolle",
    OUTGOING_LOGS: "Ausgehende Protokolle",
    LAST_LOGINS: "Letzte Anmeldungen",
    ALIAS_DOMAINS: "Alias-Domänen",
    SMARTLISTS: "Smart Lists",
    SUBADMINS: "Sub-Admins",
  },
  COMMON: {
    ACTION: "Aktion",
    ADMIN: "Admin",
    DATE: "Datum",
    DAYS_ARRAY: {
      1: "Montag",
      2: "Dienstag",
      3: "Mittwoch",
      4: "Donnerstag",
      5: "Freitag",
      6: "Samstag",
      7: "Sonntag",
    },
    RPERPAGE: "Zeile pro Seite",
    ENABLED: "Aktiviert",
    DISABLED: "Behindert",
    SAVE: "Speichern",
    STEP: "Schritt",
    CANCEL: "Stornieren",
    CLOSE: "Schließen",
    RESET: "Reset",
    NEXT: "Nächster",
    SEL_DOMAIN: "Wählen Sie Domain",
    SEL_USER: "Select User",
    SEARCH: "Suche",
    USERNAME: "Nutzername",
    USER_NAME: "Nutzername",
    DOMAIN: "Domain",
    TYPE: "Art",
    RANGE: "Netzwerkmaske",
    PASSWORD: "Passwort",
    QUOTA: "Quote (GB)",
    NEW: "Neu",
    COPY: "Kopieren",
    COPY_CLIPBOARD: "In die Zwischenablage kopieren",
    ADD: "Hinzufügen",
    LANGUAGE: "Sprache",
    LOCALIZATION: "Lokalisierung",
    DATE_FORMAT: "Datumsformat",
    UPDATE: "Aktualisieren",
    DEL_WARNING:
      "Alle Daten gehen verloren! Sind Sie sich sicher, dass Sie das entfernen wollen?",
    DEL_ITEM_WARNING:
      "All data will be lost! Are you sure you want to remove {item}?",
    DEL_CONFIRM: "Bestätigung löschen",
    HOME_COUNTRY: "Heimatland",
    TRAVEL_MODE: "Reisemodus",
    TIMEZONE: "Zeitzone",
    ACTIVE: "Aktiv",
    INACTIVE: "Inaktiv",
    YES: "Ja",
    NO: "Nein",
    NO_DATA: "Keine Daten verfügbar",
    ADDRESS: "Adresse",
    CHECK_SPAM: "Überprüfen Sie Spam",
    CHECK_VIRUS: "Überprüfen Sie den Virus",
    BROWSE: "Durchsuche",
    DESCRIPTION: "Beschreibung",
    DELETE: "Löschen",
    EDIT: "Bearbeiten",
    NOT_ALLOWED: "Nicht erlaubt",
    ALLOWED: "Dürfen",
    DAY: "Tag",
    DAYS: "Tage",
    DESTINATION: "Destination",
    VALUE: "Wert",
    FOOTER: "Fusszeile",
    MORE_DETAILS: "More details",
    OPTIONAL: "Optional",
    OTPASSWORD: "OTPassword",
    OTHER_ADMIN: "Andere Admin",
    SEARCH_OPTIONS: "Suchoptionen",
    SEARCH_RESULTS: "Suchergebnisse",
    SOURCE: "Source",
    STATUS: "Status",
    ADMIN_PANEL: "Administrationsmenü",
    SUCCESS: "Erfolg",
    ERROR: "Error",
    SUCCESS_ALERT: "Operation war erfolgreich!",
    ERROR_ALERT: "Operation wurde eingereicht!",
    WARNING: "Warnung",
    OK: "Okay",
    HI: "Hallo",
    TAB_LOGO: "Admin Panel",
    AUTO_LOGOUT: "Automatische Abmeldung",
    ALL_DOMAINS: "Alle Domänen",
    FULL_NAME: "Vollständiger Name",
    WAIT_MESSAGE: "Bitte erlauben Sie 5 Minuten, um die Änderungen zu verbreiten",
    REGULAR: "Regulär",
    REFRESH: "Aktualisierung"
  },
  AUTO_CLOSE: {
    TITLE: "Automatic Logout",
    DESC:
      "Wir haben fast 5 Minuten lang keine Aktivität festgestellt. Bleibe eingeloggt?",
    TIME_REMAIN: "{sec} Sekunden bis zum Abmelden.",
  },
  HOME: {
    ADMIN_ACCOUNT: "Administratorkonto",
    STATISTICS: "Statistiken",
    REMOTE_IP: "Remote-IP",
    BASIC_ACCOUNTS: "Grundkonten",
    ENHANCED_ACCOUNTS: "Erweiterte Konten",
    DESC1:
      "Um die Sicherheit zu erhöhen, sollten Sie die 2-Faktor-Authentifizierung für dieses Konto aktivieren",
    DESC2:
      "Installieren Sie einfach eine OTP-App wie <a href='https://www.authy.com/download'>Authy</a> und klicken Sie im Menü auf <a href='https://www.authy.com/download'>2FA</a> .",
    DESC3: "2-Faktor-Authentifizierung nicht aktiviert!",
  },
  TWOFA: {
    AUTHY: "Authy",
    TFAUTH: "Zwei-Faktor-Authentifizierung",
    SEL_STATUS: "Wählen Sie Status",
    DEC1:
      "Bitte stellen Sie sicher, dass Sie bereits eine OTP-App installiert haben, z",
    DEC2: "In Schritt 2 müssen Sie den QR-Code mit der OTP-App scannen.",
    DEC3:
      "Wenn Sie fertig sind, klicken Sie unten auf die Schaltfläche &#39;WEITER&#39;.",
    SCAN_DEC1: "Scannen Sie diesen QR-Code mit Ihrer OTP-App.",
    SCAN_DEC2:
      "Sobald das Konto hinzugefügt wurde, erhalten Sie einen 6-stelligen Code",
    SCAN_DEC3: "Geben Sie den Code in das Feld unten ein.",
    SCAN_DEC4:
      "Klicken Sie unten auf die Schaltfläche &#39;SPEICHERN&#39;, bevor Ihr Code abläuft.",
    CHALLENGE: "Herausforderung",
    DISABLE_TITLE: "Deaktivieren Sie die Zwei-Faktor-Authentifizierung",
    DDEC1:
      "Geben Sie den Code aus Ihrer OTP-App (Authy) ein, um die 2-Faktor-Authentifizierung zu deaktivieren.",
    DDEC2:
      "Klicken Sie unten auf die Schaltfläche &#39;SPEICHERN&#39;, bevor Ihr Code abläuft. Fügen Sie in diesem Fall Ihren neu generierten Code aus Ihrer OTP-App ( Authy ) erneut ein.",
  },
  USERS: {
    QUSAGE: "Quote &amp; Nutzung",
    EXPORT_RESULTS: "Ergebnisse exportieren",
    NEW_USER: "Neuer Benutzer",
    DEL_USER: "Benutzer löschen",
    ADD_USER: "Benutzer hinzufügen",
    UPDATE_USER: "Benutzer aktualisieren",
    PASS_DEC: "Bitte stellen Sie sicher, dass Sie dieses Passwort speichern",
    ACCOUNT_TYPE: "Konto Typ",
    ACCOUNT_TYPE_OEX: "OEX Konto Typ",
    NAME_OF_USER: "Name des Benutzers",
    FACTOR_AUTH: "2Factor Auth",
    ONETIME_TITLE: "Einmalpasswort erstellt!",
    ONETIME_SUCCESS: "Dieses Passwort ist nur 5 Minuten gültig!",
    BASIC: "Basic",
    ENHANCED: "Verbessert",
    NEVER: "noch nie",
    LAST_LOGIN: "Letzte Anmeldung",
    BASIC_ACCOUNT: "Grundkonto",
    ENHANCED_ACCOUNT: "Erweitertes Konto",
    AUDIT_ACCOUNT: "Audit Account",
    DISABLE_SMTP_ONLY: "Deaktiviere nur SMTP",
    USER_QUOTA: "{num1} GB frei von {num2} GB",
    USER_CONTROL_PANEL: "Benutzersteuerung",
    EXCHANGE: "Exchange Verbinder",
    EXCHANGE_ACCOUNTS: "Exchange Verbinder",
    OFFICE365: "Extern Verbinder",
    OFFICE365_ACCOUNTS: "Extern Verbinder",
    MORE_OPTIONS: "Mehr Optionen",
    LESS_OPTIONS: "Weniger Optionen",
    ONLY_LOCAL_SMTP: "Only Local SMTP",
    PERM: {
      ACCESS_HOURS: "Zugang E-Mail nur zwischen Stunden",
      APP_PASS: "Anwendungskennwörter",
      CAN_ACCESS: "Kann Zugreifen:",
      CHANGE_PASS: "Passwort ändern",
      DELIV_RULES: "Lieferregeln",
      DISABLED_INFO: "Benutzer in Rot sind entweder deaktiviert oder haben eingeschränkte Anmeldestunden",
      EXTENSIONS: "Erweiterungen",
      FOLDERS_CLEANUP: "Folders cleanup",
      FORWARDS: "Vorwärts",
      HOURS_BOTH: "Bitte geben Sie beide Startstunde und Ende Stunde",
      HOURS_SIZE: "Start Stunde muss kleiner als Ende Stunde",
      IMAP: "IMAP",
      LAST_LOGINS: "Letzte Anmeldungen",
      LOCAL_DELIV: "Lokale Lieferung",
      PERMISSIONS: "Berechtigungen",
      POP: "POP3",
      SERVICE_PERM: "Dienstberechtigungen",
      SMTP: "SMTP",
      SPAM_QUARANTINE: "Spam-Quarantäne",
      UNRESTRICTED: "Uneingeschränkt",
      UPDATE_HOURS: "Zugangszeiten aktualisieren",
      UPDATE_PERM: "Aktualisierung",
      VACATION_MSG: "Urlaubsnachricht",
      SEND_AS: "E-Mail senden als",
      CAN_SEND_AS: "Benutzer verwalten, die senden können als ",
    },
  },
  MIGRATE: {
    COMPLETE: "Complete",
    DEFAULT: "Default",
    DETAILS: "Migration Details",
    END_TIME: "End Time",
    ETA: "ETA",
    EXIT_MESSAGE: "Exit Message",
    EXIT_STATUS: "Exit Status",
    FOLDER: "Destination Folder",
    LOCAL_MIGRATION: "Local Migration",
    MIGRATE: "Migrate",
    MIGRATE_TO_FOLDER: "Migrate to Folder",
    NEW_MIGRATION: "New Migration",
    PROGRESS: "Progress",
    REFRESHING: "Refreshing every 10s",
    SCHEDULE: "Schedule",
    SECONDS_LEFT: "seconds left",
    STAGE: "Stage",
    START_TIME: "Start Time",
  },
  DOMAINS: {
    NEW_DOMAIN: "Neue Domain",
    DEL_DOMAIN: "Domain löschen",
    ADD_DOMAIN: "Domain hinzufügen",
    DOMAIN_NAME: "Domainname:",
    DOMAIN_TIMEZONE: "Domain-Zeitzone:",
    CATCHALL: "Catchall",
    WEBMAIL: "Webmail",
    EXC_ENABLED: "Exchange aktiviert",
    LOCAL_DELIVERY: "Local Delivery",
    AUDIT_ENABLED: "Audit Enabled",
    EDIT_FOOTER: "Fußzeile bearbeiten",
    FOOTER_DESC:
      "Die unten eingegebene Nachricht wird an alle von allen Benutzern gesendeten Nachrichten angehängt",
    OTHER: "Andere",
    ENHANCED_GROUP_OFFICE: "Erweitert (Group-Office)",
    GROUP_OFFICE: "GroupOffice",
    NO_CATCHALL: "Kein Catchall",
    ACCEPT_DELETE: "Akzeptieren und löschen",
    NEW_ALIAS_DOMAIN: "Neue Alias-Domain",
    ADD_ALIAS_DOMAIN: "Alias-Domain hinzufügen",
    DEL_ALIAS_DOMAIN: "Alias Domain löschen",
    DKIM_SETTINGS: "DKIM-Einstellungen",
    ENABLED: "Aktiviert",
    HOST: "Registrierung",
    KEY: "Schlüssel",
    DOMAIN_TYPE: "Domänentyp",
    VERIFYING: "Überprüfen ...",
    VERIFY: "Verifizieren",
    HOSTNAME: "Hostname",
    PLEASE_VERIFY: "Bitte überprüfen Sie den Domain -Eigentum, indem Sie diesen Datensatz zu DNS hinzufügen:",
    OEX: "OEX",
    VERIFY_AGAIN: "Erneut überprüfen",
    HEALTH: "Gesundheit",
    DOMAIN_HEALTH: "Domänengesundheit:",
    CHECK_HEALTH_INFO: "Ihre Domain ist derzeit zur Überprüfung in der Warteschlange gestellt.Bitte erlauben Sie ein paar Minuten, damit der Vorgang abgeschlossen ist.",
    MX_RECORDS: "MX-Aufzeichnungen",
    CAA_RECORD: "CAA-Aufzeichnen",
    WEBMAIL_RECORD: "Webmail-Aufzeichnen",
    WEBMAIL_SSL: "Webmail SSL",
    AUTODISCOVER_RECORD: "Autodiscover-Aufzeichnen",
    AUTODISCOVER_SSL: "Autodiscover SSL",
    SPF_RECORD: "SPF-Aufzeichnen",
    OK: "OK",
    FAIL: "Scheitern",
    INSTALLED: "Installiert",
    NOT_INSTALLED: "Nicht installiert",
    FOUND: "Gefunden",
    NOT_FOUND: "Nicht gefunden",
    EXPL_MX: "Wenn nicht ordnungsgemäß eingestellt, werden E -Mails nicht an Ihre Domain geliefert",
    EXPL_CAA: "Wenn nicht ordnungsgemäß eingestellt, können wir keine SSL -Zertifikate generieren",
    EXPL_WEBMAIL: "Wenn nicht ordnungsgemäß eingestellt, wird der Webmail -Zugriff für Ihre Domain eingeschränkt",
    EXPL_WEBMAIL_SSL: "Wenn nicht ordnungsgemäß eingestellt, sind Verbindungen zu Webmail möglicherweise unsicher sein",
    EXPL_AUTODISCOVER: "Wenn nicht ordnungsgemäß eingestellt, werden E-Mail-Clients nicht automatisch konfiguriert, was zu Setup-Problemen führt",
    EXPL_AUTODISCOVER_SSL: "Wenn nicht ordnungsgemäß eingestellt, sind Autodiscover -Verbindungen unsicher, wobei die Exposition gegenüber Daten besteht",
    EXPL_SPF: "Wenn nicht ordnungsgemäß eingestellt, können Ihre E -Mails als Spam oder abgelehnt markiert werden",
  },
  ALIASES: {
    NEW_ALIAS: "Neuer Alias",
    ADD_ALIAS: "Alias hinzufügen",
    DEL_ALIAS: "Alias löschen",
    UPDATE_ALIAS: "Alias-Update",
    ALIAS: "Alias",
    FORWARDS_TO: "Weiterleiten an",
    FORWARDS_DESTINATION: "Ziel weiterleiten",
    INTERNAL: "Intern",
    EXTERNAL: "Extern",
  },
  PUBLIC_FOLDERS: {
    ADD_FOLDER: "Ordner hinzufügen",
    ADD_OWNER: "Besitzer hinzufügen",
    ALL: "Alle",
    AUTHOR: "Autorin",
    APPOINTMENT: "Termin",
    CONTACT: "Kontakt",
    CONTAINER: "container",
    CONTRIBUTOR: "Mitwirkende",
    CREATE_ITEMS: "Elemente erstellen",
    CREATE_SUBFOLDERS: "Unterordner erstellen",
    DELETE_ITEMS: "Element löschens",
    EDIT_ALL: "Alles bearbeiten",
    EDIT_OWN: "Eigene bearbeiten",
    EDITOR: "Editorin",
    FOLDER_CONTACT: "Ordner Kontakt",
    FOLDER_DETAILS: "Ordnerdetails",
    FOLDER_NAME: "Ordnernamene",
    FOLDER_OWNER: "Ordner eigeneer",
    FOLDER_VISIBLE: "Ordner sichtbare",
    FULL_DETAILS: "Alle Einzelheiten",
    MAIL_AND_POST_ITEMS: "Post- und Postartikel",
    NO_OWNERS: "Keine Besitzer",
    NONE: "Keiner",
    NONEDITING_AUTHOR: "Nicht bewirtschafteter Autor",
    OTHER: "Andere",
    OWN: "Eigen",
    OWNER: "Eigentümer",
    OWNERS: "Besitzer",
    PROFILE: "Profil",
    PUBLIC_FOLDER: "Öffentlicher Ordner",
    PUBLISHING_AUTHOR: "Verlagautorr",
    PUBLISHING_EDITOR: "Herausgeber des Verlagsr",
    QUOTA: "Quote (GB)",
    READ: "Lesen",
    REVIEWER: "Rezensentin",
    STICKY_NOTE: "Haftnotiz",
    TASK: "Aufgabe",
    USAGE: "Verwendung",
    WRITE: "Schreiben",
  },
  AUDIT: {
    ENTRY: "Entry",
    AUDIT_EMAIL: "Audit email",
    INCOMING: "Incoming",
    OUTGOING: "Outgoing",
    AUDIT_TYPE: "Audit type",
    EMAIL_ADDRESS_DOMAIN: "Email Address or Domain",
    NO_AUDIT_DOMAIN: "No audit domain created. To enable audit for a domain, go to the Domain page.",
    NO_AUDIT_EMAIL: "No audit email created. To add a new Audit type mailbox, go to the User page.",
  },
  LISTS: {
    ADD_LIST: "Liste hinzufügen",
    NEW_LIST: "Neue Liste",
    DEL_LIST: "Liste löschen",
    ADD_MEMBER: "Mitglied hinzufügen",
    MEMBER: "Mitglied",
    MEMBER_NAME: "Mitgliedsname",
    LIST_NAME: "Listennamen",
    LIST_TYPE: "Listentyp",
    DISTRIBUTION_LIST: "Verteilerliste",
    SPAM_RETRAINER: "Spam Retrainer",
    NOT_SPAM_RETRAINER: "Nicht-Spam-Retrainer",
    EXCHNAGE_ACCOUNT: "Exchange-Konto",
    EMAIL_ADDRESS: "E-Mail-Addresse",
    EMAIL_ADDRESS_DOMAIN: "E-Mail-Adresse oder Domäne",
    INTERNAL_MEMBERS: "Interne Mitglieder",
    EXTERNAL_MEMBERS: "Externe Mitglieder",
  },
  SMARTLISTS: {
    ADD_LIST: "Add Smart List",
    NEW_LIST: "New Smart List",
    DEL_LIST: "Delete Smart List",
    UPDATE_LIST: "Update Smart List",
    SPECIFY_MEMBERS: "Specify Members",
    ALL_DOMAIN_USERS: "All domain users",
    DG_TYPE: "Dg type",
    LIST_PROTECTION: "List protection",
    ONLY_MEMBERS_POST: "Only members can post",
    PASSWORD_PROTECTED: "Password protected",
    NO_PROTECTION: "No protection",
    SEND_LABEL: "Send a copy of the message to the sender as well",
    MEMBER_MANAGEMENT: "Member Management",
    CAN_POST: "Can post",
    CAN_RECEIVE: "Receive list messages",
    MEMBERS_LIST: "Members List",
    MEMBERS: "Members",
    ALL_MEMBER_POST: "All members can post",
    MY_SUBJECT: "My Subject",
    PASSWORD_TXT:
      "When sending an email, enter this password at the beginning of the subject, in brackets.",
    EX: "EX",
  },
  FILTERING: {
    SETTINGS: {
      FILTER_SETTINGS: "Filtereinstellungen",
      ENABLE_AUTOWHITELIST: "Aktivieren Sie die Autowhitelist",
      FILTER_SENSITIVITY: "Filterempfindlichkeit",
      KEEP_SPAM_FOR: "Halten Sie Spam für",
      SEND_SPAM_REPORT: "Spam-Bericht senden",
      SPAM_REPORT_CONTENT: "Inhalt des Spam-Berichts",
      SPAM_REPORT_FORMAT: "Spam-Berichtsformat",
      LOGIN_LOCATION_LIMIT: "Anmeldeortlimit",
      NORMAL_SENSITIVITY: "Normale Empfindlichkeit",
      MOST_SPAM: "Der meiste Spam kommt durch",
      MOST_MESSAGE: "Die meisten Nachrichten werden gefiltert",
      ALL_SPAM_MESSAGES: "Alle Spam-Nachrichten",
      LATEST_SPAM_MESSAGES: "Neueste Spam-Nachrichten",
      HTML_TEXT: "HTML und TEXT",
      HTML_ONLY: "Nur HTML",
      TEXT_ONLY: "Nur Text",
      DONT_SEND: "Nicht senden",
      EVERY_HOUR: "Alle 1 Stunde",
      EVERY_HOURS: "Alle {num} Stunden",
    },
    WHITELIST: {
      IGNORE_SPF: "SPF ignorieren",
      IGNORE_SPF_IP: "SPF-IP ignorieren",
      SENDER_IP: "IP-Adresse des Absenders",
      IMPORT_DEC:
        "Bitte laden Sie eine Datei im CSV-Format hoch, eine Adresse pro Zeile",
      ADD_WHITELIST: "Whitelist hinzufügen",
      NEW_WHITELIST: "Neue Whitelist",
      DEL_WHITELIST: "Whitelist löschen",
      IMPORT: "Importieren",
      CHOOSE_PLACEHOLDER: "Wählen Sie eine CSV oder legen Sie sie hier ab ...",
      DROP_PLACEHOLDER: "Datei hier ablegen ...",
      UPDATE_WHITELIST: "Whitelist aktualisieren",
      INCLUDE_USERS_WHITELIST: "Fügen Sie die Whitelist der Benutzer hinzu",
      APPLY_TO_HEADERS: "Apply to Headers",
    },
    BLACKLIST: {
      NEW_BLACKLIST: "Neue schwarze Liste",
      ADD_BLACKLIST: "Blacklist hinzufügen",
      DEL_BLACKLIST: "Blacklist löschen",
      UPDATE_BLACKLIST: "Blacklist aktualisieren",
      INCLUDE_USERS_BLACKLIST: "Fügen Sie die Blacklist der Benutzer hinzu",
    },
    AUTO_WHITELIST: {
      ADDRESS_LIKE: "Adresse wie",
      PICK_DATE: "Wähle ein Datum",
    },
    IPACCESS_LISTS: {
      PREFERENCE: "Präferenz",
      SELECT_IPTYPE: "Wählen Sie IP-Typ",
    },
  },
  BRANDING: {
    FORM_DESC: "Diese Einstellungen gelten für alle Benutzer der Domäne",
    COMPANY_COLOR: "Firmenfarbe",
    COMPANY_NAME: "Name der Firma",
    COMPANY_WEBLINK: "Firmen-Weblink",
    SUPPORT_EMAIL: "Support-E-Mail",
    COMPANY_LOGO: "Firmenlogo",
    DEFAULT_LOGO: "Standardlogo",
    DEFAULT_LOGO_DESC:
      "Standardlogo für die grundlegende Group-Office-Oberfläche",
    UPDATE_SETTINGS: "Update Einstellungen",
    PREVIEW: "Vorschau",
    PREVIEW_LOGO: "Vorschau des Logos",
  },
  SETTINGS: {
    CHANGE_PASSWORD: "Ändere das Passwort",
    CHNAGE_EMAIL: "Ändern Sie die E-Mail",
    CHANGE_PASSWORD_DESC: "Sie können Ihr Passwort ändern",
    SYSTEM_TEXTS: "Systemtexte",
    QUOTA: "Quotenalarm CC",
    NEW_PASSWORD: "Neues Kennwort",
    CONFIRM_PASSWORD: "Bestätige das Passwort",
    CURRENT_EMAIL: "Aktuelle E-Mail",
    NEW_EMAIL: "Neue e-mail",
    CHANGE_SYSTEXT: "Systemtexte ändern",
    TEXT_FOR: "Text für",
    TEXT: "Text",
    TIMEOUT: "Zeitüberschreitung im Admin-Bereich",
    MINUTES: "Minuten",
    CHANGE_QUOTA_ALERT: "Kontingentalarm ändern",
    QUOTA_ALERT: "Quotenalarm",
    CHANGE_ADMIN_LOCALE: "Ändern Sie das Gebietsschema des Administrators",
    CHANGE: "Veränderung",
    FORGOT_PASSWORD_MAIL_TEXT: "Admin Mail Password Text zurücksetzen",
    FORGOT_PASSWORD_WEB_TEXT: "Admin Web Password Reset Text",
    QUOTA_WARNING_TEXT: "Kontingentwarnungstext",
    QUOTA_WARNING_SUBJECT: "Kontingentwarnung Betreff",
    FORGOT_PASSWORD_MAIL_SUBJECT: "Betreff E-Mail zurücksetzen Betreff",
    PASSWORD_EXPIRY_WARNING_TEXT: "Warnungstext zum Ablauf des Passworts",
    PASSWORD_EXPIRY_WARNING_SUBJECT: "Betreff zum Ablauf des Passworts",
    PASSWORD_EXPIRED_WARNING_TEXT: "Passwort abgelaufen Warnungstext",
    PASSWORD_EXPIRED_WARNING_SUBJECT: "Passwort abgelaufen Warnung Betreff",
  },
  LOGS: {
    INCOMING_LOGS: {
      DELIVERED: "Geliefert",
      UNDELIVERED: "Nicht zugestellt",
      DELIVERED_TO: "Geliefert an",
      ENVELOPE_FROM: "Umschlag von",
      TO: "Zu",
      SUBJECT: "Gegenstand",
      IP: "IP",
      SPAM_SCORE: "Spam-Score",
      DNSBL: "DNSBL",
      SPF: "SPF",
      DELIVERE: "Liefern",
      WHITELISTED: "Whitelist",
      WHITELIST_DELIVER: "Whitelist &amp; Deliver",
      HEADER_FROM: "Header von",
      REJECTED: "Abgelehnt",
      DETAILS: "Einzelheiten",
      MORE: " & NOCH {num}",
    },
    OUTGOING_LOGS: {
      VIEW_MESSAGES: "Nachrichten anzeigen",
      SENT_FROM: "Gesendet von",
      SENDER_IP: "IP senden",
      SENT_TO: "Gesendet an",
      FROM_DATE: "Ab Datum",
      TO_DATE: "Miteinander ausgehen",
      DELIVERED: "Geliefert",
      TEMPORARILY_REJECTED: "Vorübergehend abgelehnt",
      PERMANENTLY_REJECTED: "Dauerhaft abgelehnt",
      ALL_MESSAGES: "Alle Nachrichten",
      DESC:
        "Diese Schnittstelle zeigt keine Lieferungen mit derselben Domain an",
      RECIPIENT_STATUS: "Lieferstatus des Empfängers",
      MESSAGE_STATUS: "Status der Nachrichtenübermittlung",
    },
  },
  LAST_LOGINS: {
    START_DATE: "Anfangsdatum",
    END_DATE: "Endtermin",
    NEGATE_CONDITION: "Zustand negieren",
    PASSWORD_USED: "Passwort verwendet",
    SERVICE_USED: "Service verwendet",
    RESET_SEARCH: "Suche zurücksetzen",
    LOCATION: "Ort",
    IMAP: "imap",
    POP3: "pop3",
    SMTP: "smtp",
    SIEVE: "sieve",
    LOGIN: "login",
    BLOCKED: "Blockiert",
  },
  SUB_ADMINS: {
    ACCOUNT_HISTORY: "Kontoverlauf",
    DGLISTS: "Intelligente Listen",
    NO_ACCESS: "Kein Zugang",
    VIEW: "Aussicht",
    ENABLE_DISABLE: "Aktivieren / Deaktivieren",
    REMOVE: "Entfernen",
    IMPERSONATE: "Imitieren",
    ADD_UPDATE: "Hinzufügen / Aktualisieren",
    MODIFY: "Ändern",
    ADD_SUBADMIN: "Subadmin hinzufügen",
    DESC:
      "Zu den Berechtigungen der höheren Ebene gehören die Berechtigungen vor ihnen",
  },
  SECURITY: {
    ACTION:
      "Bei diesen Benutzern ist möglicherweise ihr Konto gefährdet. Ändern Sie sofort ihr Kennwort und weisen Sie den Benutzer an, einen Antivirenscan durchzuführen.",
    ADMIN_ACCESS_INFO: "Sie müssen der Liste mindestens eine IP hinzufügen", 
    ALLOW_BUTTON: "Uneingeschränkten Zugang Admin",
    COUNTRIES: "Verschiedene Anmeldeländer",
    ENABLED_COUNTRY: "Für Logins aus mehr als {num} Ländern aktiviert",
    INFO:
      "Klicken Sie auf den Benutzer, um seine letzten Anmeldungen anzuzeigen.",
    IP: "IP",
    IP_DESC: "Warum vertrauen Sie diese IP: Name des Orts oder der Service.",
    IP_BLOCKED: "IP-Adresse ist blockiert",
    IP_NOT_BLOCKED: "IP-Adresse ist nicht blockiert",
    MARK_SAFE : "Als sicher markieren",
    MONITOR_LOGINS: "Überwachen Sie verdächtige Anmeldungen",
    NONE:
      "In den letzten 24 Stunden wurden keine Sicherheitsvorfälle festgestellt.",
    RESTRICT_BUTTON: "Beschränken Sie den Admin-Zugang nur auf die IPs in der Liste",
    RESTRICT_STATUS_ACTIVE: "Admin-Zugang ist in dieser Liste nur die IP-Adressen beschränkt",
    RESTRICT_STATUS_FREE: "Admin-Zugang ist unbeschränkt",
    RESTRICT_WARNING: "Warnung: Stellen Sie sicher, dass Ihr IP ist in der Admin-IP-Zugriffsliste. Andernfalls könnten Sie sich aussperren.",
    RESTRICT: "Beschränken",
    SECURITY_WARNING:
      "Neue Sicherheitsvorfälle erkannt. Klicken Sie hier, um die Sicherheitsseite zu überprüfen.",
    SENT: "E-Mails gesendet",
    TYPE_DESC: "Wählen Sie, ob Sie die IP oder das gesamte Netzwerk als sicher markieren möchten",
    YOUR_IP: "Beachtung! Ihre aktuelle IP ist {ip}, und ist nicht in der Admin-IP-Zugriffsliste. Wenn Sie fortfahren, werden Sie sich aussperren!",
    VERIFY_IP: "Überprüfen Sie, ob die IP blockiert ist",
  },
  VALIDATION: {
    REQUIRED_FIELD: "Dies ist ein Pflichtfeld.",
    DOMAIN_FIELD: "Dies ist ein Pflichtfeld und muss eine gültige Domäne sein.",
    USERNAME_FIELD:
      "Dies ist ein Pflichtfeld und muss ein gültiger Benutzername sein.",
    ALIAS_FIELD: "This is a required field and must be valid alias.",
    EMAIL_FIELD: "Dies ist ein Pflicht- und E-Mail-Feld.",
    EMAIL_DOMAIN_FIELD: "Dies ist ein erforderliches E-Mail- oder Domänenfeld.",
    QUOTA_FIELD: "Das verfügbare Quote beträgt {num} GB.",
    QUOTA_ZERO: "Keine Quote mehr.",
    REQUIRED_MINLENGTH_FIELD:
      "Dies ist ein Pflichtfeld und muss mindestens {num} Zeichen umfassen.",
    IDENTICAL_FIELD: "Dies ist ein Pflichtfeld und muss identisch sein.",
    IP_INVALID: "Falsche IP-Adresse",
    FILL_ALL_FIELDS: "Bitte füllen Sie alle Felder aus.",
    MAX_VALUE: "Dies ist ein Pflichtfeld und muss kleiner sein als {num}",
    MAX_LETTERS: "Muss lange höchstens {num} Zeichen lang sein.",
    MIN_LETTERS: "Muss mindestens {num} Zeichen lang sein.",
    REGEX: "Bitte stimmen Sie mit dem akzeptierten Format überein: {rule}",
    NUMBERS: "Zahlen",
    LETTERS: "Briefe",
    LOWERCASE_LETTER: "Muss einen Kleinbuchstaben enthalten.",
    UPPERCASE_LETTER: "Muss einen Großbuchstaben enthalten.",
    NUMBER_LETTER: "Muss eine Nummer enthalten.",
    SPECIAL_LETTER: "Muss ein Sonderzeichen enthalten.",
  },
  AUTH: {
    GENERAL: {
      OR: "Oder",
      SUBMIT_BUTTON: "einreichen",
      NO_ACCOUNT: "Sie haben noch keinen Account?",
      SIGNUP_BUTTON: "Anmelden",
      FORGOT_BUTTON: "Passwort vergessen",
      BACK_BUTTON: "Zurück",
      PRIVACY: "Privatsphäre",
      LEGAL: "Legal",
      CONTACT: "Kontakt",
      WELCOME_ADMIN: "Willkommen in der Admin-Systemsteuerung!",
    },
    LOGIN: {
      TITLE: "Account Login",
      BUTTON: "Einloggen",
      DESC1:
        "Willkommen in unserem neuen Admin Panel! Um auf die Legacy-Version zuzugreifen, besuchen Sie bitte:",
      DESC2:
        "Wenn Sie 2FactorAuthentication aktiviert haben, fügen Sie Ihren 6-stelligen Code am Ende Ihres Passworts hinzu",
      DESC3:
        "Holen Sie sich den Code von der Twofa Authenticator-App auf Ihrem Mobilgerät und geben Sie ihn unten ein.",
      DESC4:
        "Sie müssen den Zwei-Faktor-Authentifizierungscode nicht mehr an das Passwort anhängen.",
      TFA_INVALID: "Ungültiger Code oder der Code ist abgelaufen",
      TFA: "Dein Code:",
    },
    FORGOT: {
      TITLE: "Passwort vergessen?",
      SEND: "Senden",
      DESC:
        "Wenn Ihr Benutzername mit Ihrer E-Mail-Adresse übereinstimmt, geben Sie ihn bitte zweimal ein",
    },
    RESET_PASSWORD: {
      TITLE: "Passwort zurücksetzen ...",
      DESC:
        "Klicken Sie auf die Schaltfläche und wir generieren ein neues Passwort.",
      GENERATE_NEW_PASSWORD: "Neues Passwort generieren",
      GO_LOGIN: "Gehen Sie zur Anmeldeseite",
    },
    REGISTER: {
      TITLE: "Anmelden",
      DESC: "Geben Sie Ihre Daten ein, um Ihr Konto zu erstellen",
      SUCCESS: "Ihr Konto wurde erfolgreich registriert.",
    },
    INPUT: {
      EMAIL: "Email",
      PASSWORD: "Passwort",
      USERNAME: "Nutzername",
    },
    SIGN_OUT: "Ausloggen",
    RESET: "Zurücksetzen",
    VALIDATION: {
      EMAIL: "E-Mail ist erforderlich und eine gültige E-Mail-Adresse.",
      PASSWORD: "Passwort wird benötigt.",
      USERNAME: "Ein Benutzername ist erforderlich.",
    },
  },
  ZULIP:{
    IS_ADMIN: "Is Admin",
    UPDATE: "Update Zulip User",
    ERROR_STATUS: "Error on status update",
    ZULIP_ACCOUNTS: "Zulip Accounts",
  },
};
