import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/home",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/home",
          name: "home",
          component: () => import("@/views/pages/Home.vue"),
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/views/pages/Builder.vue"),
        },
        {
          path: "/domains",
          name: "domains",
          component: () => import("@/views/pages/index.vue"),
          children: [
            {
              path: "domainlist",
              name: "domainlist",
              component: () => import("@/views/pages/domains/Domains.vue"),
            },
            {
              path: "aliasdomains",
              name: "aliasdomains",
              component: () => import("@/views/pages/domains/AliasDomains.vue"),
            },
            {
              path: "brandingdomains",
              name: "brandingdomains",
              component: () => import("@/views/pages/domains/Branding.vue"),
            },
          ],
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/views/pages/index.vue"),
          children: [
            {
              path: "list",
              name: "list",
              component: () => import("@/views/pages/users/Users.vue"),
            },
            {
              path: "migrations",
              name: "migrations",
              component: () => import("@/views/pages/users/Migrations.vue"),
            },
          ]
        },
        {
          path: "/chatusers",
          name: "chatusers",
          component: () => import("@/views/pages/Chat.vue"),
        },
        {
          path: "/lists",
          name: "lists",
          component: () => import("@/views/pages/Lists.vue"),
        },
        {
          path: "/smartlists",
          name: "smartlists",
          component: () => import("@/views/pages/SmartLists.vue"),
        },
        {
          path: "/aliases",
          name: "aliases",
          component: () => import("@/views/pages/Aliases.vue"),
        },
        {
          path: "/publicfolders",
          name: "publicfolders",
          component: () => import("@/views/pages/PublicFolders.vue"),
        },
        {
          path: "/audit",
          name: "audit",
          component: () => import("@/views/pages/Audit.vue"),
        },
        {
          path: "/security",
          name: "security",
          component: () => import("@/views/pages/index.vue"),
          children: [
            {
              path: "aipaccesslists",
              name: "aipaccesslists",
              component: () => import("@/views/pages/security/AdminIpaccesslists.vue"),
            },
            {
              path: "/twofa",
              name: "twofa",
              component: () => import("@/views/pages/Twofa.vue"),
            },
            {
              path: "/securityincidents",
              name: "securityincidents",
              component: () => import("@/views/pages/SecurityIncidents.vue"),
            },
            {
              path: "trustedips",
              name: "trustedips",
              component: () => import("@/views/pages/security/Trustedips.vue"),
            },
          ],
        },
        {
          path: "/subadmins",
          name: "subadmins",
          component: () => import("@/views/pages/SubAdmins.vue"),
        },
        {
          path: "/branding",
          name: "branding",
          component: () => import("@/views/pages/Branding.vue"),
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/views/pages/Settings.vue"),
        },
        {
          path: "/filtering",
          name: "filtering",
          component: () => import("@/views/pages/index.vue"),
          children: [
            {
              path: "settings",
              name: "fsettings",
              component: () => import("@/views/pages/filtering/Settings.vue"),
            },
            {
              path: "autowhitelist",
              name: "autowhitelist",
              component: () =>
                import("@/views/pages/filtering/AutoWhitelist.vue"),
            },
            {
              path: "blacklist",
              name: "blacklist",
              component: () => import("@/views/pages/filtering/Blacklist.vue"),
            },
            {
              path: "whitelist",
              name: "whitelist",
              component: () => import("@/views/pages/filtering/Whitelist.vue"),
            },
            {
              path: "ipaccesslists",
              name: "ipaccesslists",
              component: () =>
                import("@/views/pages/filtering/Ipaccesslists.vue"),
            },
          ],
        },
        {
          path: "/logs",
          name: "logs",
          component: () => import("@/views/pages/index.vue"),
          children: [
            {
              path: "incominglogs",
              name: "incominglogs",
              component: () => import("@/views/pages/logs/Incominglogs.vue"),
            },
            {
              path: "outgoinglogs",
              name: "outgoinglogs",
              component: () => import("@/views/pages/logs/Outgoinglogs.vue"),
            },
            {
              path: "lastlogins/:user?",
              name: "lastlogins",
              component: () => import("@/views/pages/logs/Lastlogins.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/views/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/views/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/views/pages/error/Error-2.vue"),
        },
        {
          path: "/forgotPass",
          name: "forgotPass",
          component: () => import("@/views/pages/error/ForgotPass.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/views/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/views/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/views/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login"),
        },
        // {
        //   name: "register",
        //   path: "/register",
        //   component: () => import("@/views/pages/auth/Register"),
        // },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue"),
    },
  ],
});
