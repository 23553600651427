const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveLoginUser = (username) => {
  window.localStorage.setItem("current_user", username);
};

export const saveUserRole = (role) => {
  window.localStorage.setItem("user_role", role);
};

export const saveTimeout = (milisec) => {
  window.localStorage.setItem("timeout", milisec);
};

export const saveTfaState = (state) => {
  window.localStorage.setItem("isTfa", state);
};
export const saveTfaAllowedState = (state) => {
  window.localStorage.setItem("isTfaAllowed", state);
};
export const saveSecurityWarningState = (state) => {
  window.localStorage.setItem("securityWarning", state);
};
export const saveSecurityWarningLevel = (level) => {
  window.localStorage.setItem("securityWarningLevel", level);
};
export const savePermission = (permissions) => {
  window.localStorage.setItem(
    "perm_accounthistory",
    permissions.accounthistory
  );
  window.localStorage.setItem("perm_users", permissions.users);
  window.localStorage.setItem("perm_domains", permissions.domains);
  window.localStorage.setItem("perm_aliases", permissions.aliases);
  window.localStorage.setItem("perm_lists", permissions.lists);
  window.localStorage.setItem("perm_dglists", permissions.dglists);
  window.localStorage.setItem("perm_branding", permissions.branding);
  window.localStorage.setItem("perm_filtering", permissions.filtering);
  window.localStorage.setItem("perm_logs", permissions.logs);
  window.localStorage.setItem("perm_subadmins", permissions.subadmins);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default {
  getToken,
  saveToken,
  saveLoginUser,
  saveUserRole,
  saveTimeout,
  saveTfaState,
  saveTfaAllowedState,
  saveSecurityWarningState,
  saveSecurityWarningLevel,
  savePermission,
  destroyToken,
};
