import ApiService from "@/common/api.service";
export function postRequest(data) {
  return new Promise((resolve) => {
    ApiService.post("", data)
      .then((data) => {
        resolve(data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}
